import React from "react";
import api from "../../utils/ApiMethod";
import { useEffect } from "react";
import { useState } from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import ProfileCard from "../../components/common/ProfileCard";
import { Container } from "@chakra-ui/react";
import pathConverter from "../../components/common/pathConverter";

const Introduction = () => {
  const [aboutUsPageData, setAboutUsPageData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/introduction");
      setAboutUsPageData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div>
      {aboutUsPageData &&
        aboutUsPageData?.map((elem, index) => (
          <>
            <Breadcrumb
              pageName="About Us"
              pageTitle="About Us"
              id="#about"
              img_url={`https://api.biharsports.org/${elem?.bannerUrl?.replace(
                "public/images/",
                "images/"
              )}`}
              mobile_banner={`https://api.biharsports.org/${elem?.mobileBannerUrl?.replace(
                "public/images/",
                "images/"
              )}`}
            />
            <div
              style={{ width: "100%", margin: "20px auto" }}
              className="container"
            >
              <ProfileCard
                heading={aboutUsPageData[0]?.heading}
                quotes={aboutUsPageData[0]?.paragraph}
                image={`https://api.biharsports.org/${elem?.pageImage?.replace(
                  "public/images/",
                  "images/"
                )}`}
              />
            </div>
          </>
        ))}
    </div>
  );
};
export default Introduction;
