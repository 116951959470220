import React, { useEffect, useState } from "react";
import "./Indivisiual.css";
import { SimpleGrid, border } from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import { delay, motion, useAnimation } from "framer-motion";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Box, Text, Image } from "@chakra-ui/react";

const DeputyChiefMinister = () => {
  const [dptCMData, setDptCMData] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/dyptcmmessage");
      setDptCMData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  console.log(dptCMData, "harjot");

  return (
    <div>
      {/* {dptCMData &&
        dptCMData.map((dptCMData, index) => ( */}
      <>
        <Breadcrumb
          pageName=" Bihar Chief minister message"
          pageTitle="Chief minister message"
          id="#chief-minister"
          img_url={`https://api.biharsports.org/${dptCMData[0]?.bannerUrl?.replace(
            "public/images/",
            "images/"
          )}`}
          mobile_banner={`https://api.biharsports.org/${dptCMData[0]?.mobileBannerUrl?.replace(
            "public/images/",
            "images/"
          )}`}
        />

        <SimpleGrid columns={[1, 1, 2, 2]} mt="20" mb="10">
          <motion.Box
            flexShrink={0}
            initial={{
              opacity: 0,
              translateX: -50,
              translateY: 0,
            }}
            whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
            transition={{ duration: 0.3, delay: 1 }}
          >
            <Image
              //   style={{borderTop:"6px solid red", borderBottom:"6px solid red"}}
              borderRadius="lg"
              px={[5, 10, 20]}
              // src={`https://api.biharsports.org/${dptCMData[0]?.pageImage?.replace(
              //   "public/images/",
              //   "images/"
              // )}`}
              src="/img/SamrathChaudhary.jpeg"
              alt="SamrathChaudhary"
              h="350px"
              w="100%"

            />
          </motion.Box>

          <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
            <Text
              fontWeight="bold"
              textTransform="uppercase"
              fontSize={["14", "32"]}
              letterSpacing="wide"
              color="teal.800"
              px={[4, 10, 20]}
            >
              {dptCMData[0]?.heading}
            </Text>

            <Text
              fontWeight="bold"
              textTransform="uppercase"
              align={"right"}
              fontSize={["10", "13"]}
              letterSpacing="wide"
              color="black"
              px={[4, 10, 20]}
            >
              {dptCMData[0]?.designation}
            </Text>

            <Text
              mt={2}
              color="gray.500"
              align={"left"}
              px={[5, 10, 20]}
              letterSpacing="wide"
              fontWeight="bold"
              py="2"
            >
              {expanded ? (
                <p>{dptCMData[0]?.paragraph}</p>
              ) : (
                <p>{dptCMData[0]?.paragraph?.slice(0, 850)}...</p>
              )}
              <button
                onClick={toggleExpand}
                style={{ color: "blue", fontWeight: "500" }}
              >
                {expanded ? "Read less" : "Read more"}
              </button>

              {/* {dptCMData[0]?.paragraph} */}
            </Text>
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={[1, 1, 2, 2]} mt="20" mb="10">
          <motion.Box
            flexShrink={0}
            initial={{
              opacity: 0,
              translateX: -50,
              translateY: 0,
            }}
            whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
            transition={{ duration: 0.3, delay: 1 }}
          >
            <Image
              //   style={{borderTop:"6px solid red", borderBottom:"6px solid red"}}
              borderRadius="lg"
              px={[5, 10, 20]}
              // src={`https://api.biharsports.org/${dptCMData[0]?.pageImage?.replace(
              //   "public/images/",
              //   "images/"
              // )}`}
              src="/img/VinayKumarSinha.jpeg"
              alt="SamrathChaudhary"
              h="350px"
              w="100%"

            />
          </motion.Box>

          <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
            <Text
              fontWeight="bold"
              textTransform="uppercase"
              fontSize={["14", "32"]}
              letterSpacing="wide"
              color="teal.800"
              px={[4, 10, 20]}
            >
              {/* {dptCMData[0]?.heading} */}Shri Vijay Kumar Sinha

            </Text>

            <Text
              fontWeight="bold"
              textTransform="uppercase"
              align={"right"}
              fontSize={["10", "13"]}
              letterSpacing="wide"
              color="black"
              px={[4, 10, 20]}
            >
              {/* {dptCMData[0]?.designation} */}Hon'ble Deputy Chief Minister, Bihar
            </Text>

            <Text
              mt={2}
              color="gray.500"
              align={"left"}
              px={[5, 10, 20]}
              letterSpacing="wide"
              fontWeight="bold"
              py="2"
            >
              {expanded ? (
                <p>
                  {/* {dptCMData[0]?.paragraph} */}
                  The land of knowledge, Bihar is making its own identity in the field of sports. On one hand, the wonderful performance of the players of Bihar in various sports competitions has increased the prestige of the state, on the other hand, the participation of the youth of the state in various sports has also increased. The Government of Bihar is committed and working for the all-round development of sports and players in the state. For which a sports department has been formed in the state. Along with this, the Government of Bihar has also been organizing timely sports activities to promote sports in all the districts of the state. Bihar is a state full of talents, which our young players are proving by winning medals in various sports competitions. Keeping in mind the development of sports and players, many sports policies have also been made by the Government of Bihar and in future also we will continue to make plans related to the development of sports and players. I wish a bright future to all the players of the state.
                </p>
              ) : (
                <p>
                  {/* {dptCMData[0]?.paragraph?.slice(0, 850)}... */}
                  The land of knowledge, Bihar is making its own identity in the field of sports. On one hand, the wonderful performance of the players of Bihar in various sports competitions has increased the prestige of the state, on the other hand, the participation of the youth of the state in various sports has also increased. The Government of Bihar is committed and working for the all-round development of sports and players in the state. For which a sports department has been formed in the state. Along with this, the Government of Bihar has also been organizing timely sports activities to promote sports in all the districts of the state. Bihar is a state full of talents, which our young players are proving by winning medals in various sports competitions. Keeping in mind the development of sports and players, many sports policies have also been made by the Government of Bihar and in future also we will continue to make plans related to the development of sports and players. I wish a bright future to all the players of the state.
                </p>
              )}
              <button
                onClick={toggleExpand}
                style={{ color: "blue", fontWeight: "500" }}
              >
                {expanded ? "Read less" : "Read more"}
              </button>

              {/* {dptCMData[0]?.paragraph} */}
            </Text>
          </Box>
        </SimpleGrid>
      </>
      {/* ))} */}
    </div>
  );
};
export default DeputyChiefMinister;
