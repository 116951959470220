import { SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from "../tenders/style.module.css";
import Container from "react-bootstrap/Container";
import api from "../../utils/ApiMethod";
import img from "../../assets/images/Download.jpeg";
// import Styles from "../../styles/Tender.module.css";
import Styles from "../../styles/Tender.module.css";
const CareerAndOpportunity = () => {
  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("/careerandopportunity");
      const data = await fetch("https://bssa-database.vercel.app/careerandopportunity");
      const res = await data.json();
      setData(res);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    // <div>
    <div className={styled.Container}>
      <h1 className="pt-2" style={{ marginBottom: "20px" , marginTop: "20px" }}>
        Career
      </h1>
      {/* input field for search the item */}
      {/* <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for tender"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div> */}


      <table class="table table-striped ">
        <thead>
          <tr class="table-primary">
            <th className={`${Styles.th}`} scope="col" style={{ width: "2rem" }}>
              Sl.No.
            </th>
            <th className={`${Styles.head}`} scope="col">
              Heading
            </th>

            <th className={`${Styles.head}`} scope="col">
              Last date To Apply
            </th>
            <th className={`${Styles.head}`} scope="col">
              For More Information
            </th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter((item) =>
              item.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((item, i) => (
              <tr>
                <th scope="row" style={{ width: "3rem" }}>
                  {i + 1}
                </th>
                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.name}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.expiryDate}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  <a href={item.url} target="_blank">
                    <img
                      className={Styles.table_image}
                      src={"img/icons8-view-100.png"}
                    />
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
        {/* <tbody>

          <tr>
            <th scope="row">{1}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              Nutritionist
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/Nutritionist.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>
         

          <tr>
            <th scope="row">{2}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              Young Professional
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/young_Perfessional.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>


          <tr>
            <th scope="row">{3}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              Strength & Conditioning Expert
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/StrengthandConditioningExpert.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>


          <tr>
            <th scope="row">{4}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              Psychologist
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/HiringPsychologist.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>

  

          <tr>
            <th scope="row">{5}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            Physiotherapist
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/Hiring_Physiotherapist.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>

          <tr>
            <th scope="row">{6}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            Masseur
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/Hiring_Masseur.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>
  
          <tr>
            <th scope="row">{7}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            Assistant Coach
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/Assistant_Coach.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>

          <tr>
            <th scope="row">{8}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            Head Coach
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/HeadCoach.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>

          <tr>
            <th scope="row">{9}</th>
            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            High Performance Manager
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
            25th November 2023
            </td>

            <td className={Styles.table_cell} style={{ textAlign: "left" }}>
              <a href={'/img/High_PerformanceManager.jpeg'} target="_blank">
                <img className={Styles.table_image} src={'img/icons8-view-100.png'} />
              </a>
            </td>
          </tr>


        </tbody> */}
      </table>
    </div>
    // </div>
  );
};

export default CareerAndOpportunity;
