import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Gift.module.css";
import api from "../utils/ApiMethod";
import { AspectRatio, Button, useMediaQuery } from "@chakra-ui/react";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import gallerystyles from "../styles/GalleryThree.module.css";

import { Navigation } from "swiper/modules";

function VideoCarousal() {
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/video");
      setData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const slidesPerView = isSmallScreen ? 1 : 1;
  return (
    <>
      <div
        className={gallerystyles.gallerycontainer}
        style={{
          paddingBottom: "10px",
          //   border: "2px solid #fcb606",
        }}
      >
        <div className={gallerystyles.head}>
          <a href="/videos" title="Video Gallery">
            <Button colorScheme="yellow" size="lg" fontSize="16" py={8}>
            Video Gallery⨠
            </Button>
          </a>
        </div>

        <Swiper
          autoplay={{
            delay: 2000,
          }}
          // autoplay={{
          //   delay: 2000, // Autoplay delay in milliseconds
          //   disableOnInteraction: true, // Autoplay stops even after user interaction
          // }}
          slidesPerView={slidesPerView}
          // rewind={true}
          navigation={true}
          modules={[Navigation]}
          loop={true}
          spaceBetween={10}
          bgGradient={[
            "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
          ]}
          className={styles.mySwiper}
        >
             {data && data[0]?.videoItems.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{width:"100%", height:"100%"}} >
                    <div>
                      <AspectRatio maxW="560px" ratio={3 / 2} bg={"#0f6b39"}>
                        <iframe
                          title="BSSA-YouTube"
                          // src={item?.videoUrl}
                          src= {`${item?.videoUrl}?rel=0`}
                          allowFullScreen
                        />
                      </AspectRatio>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
export default VideoCarousal;