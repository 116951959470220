import { SimpleGrid, Square, Text, Box, Center, Container } from '@chakra-ui/react';
import React, { useState, useEffect } from "react";
import api from '../../utils/ApiMethod'
import Breadcrumb from "../../components/common/Breadcrumb";

const Inspiration = () => {


  const [commingData, setCommingData] = useState([])

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("/get/prena");
      const data = await api.fetchData("api/prena"); // url for db.json
      setCommingData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);


  return (
    <>
      <div style={{ backgroundImage: 'linear-gradient(to bottom, #0a3272, white)' }}>

        <Breadcrumb
          pageName=" Sports Requirement Policy"
          pageTitle=" Sports Requirement Policy"
          id="#Our-Sports-Legacy"
          img_url={`https://api.biharsports.org/${commingData[0]?.bannerUrl.replace(
            "public/images/",
            "images/"
          )}`}

          // img_url={`http://localhost:5000/${commingData[0]?.bannerUrl.replace(
          //     "public/images/",
          //     "images/"
          //   )}`}
          // mobile_banner={`http://localhost:5000/${commingData[0]?.mobileBannerUrl.replace(
          //   "public/images/",
          //   "images/"
          // )}`}
          mobile_banner={`https://api.biharsports.org/${commingData[0]?.mobileBannerUrl.replace(
            "public/images/",
            "images/"
          )}`}
        />

        <Text
          // fontWeight="bold"
          textTransform="uppercase"
          p={'4px'}
          pb={'12px'}
          // align={"right"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          color="black"
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight='extrabold'
        >
          {commingData[0]?.text}
        </Text>

        <SimpleGrid columns={1} spacingX={8} mb={7} mt={5}>
          {commingData[0]?.prenaItem.map((elem, index) => (
            <>
              <Center as={"text"} textAlign={'justify'} p='3%'
                fontWeight="bold"
                key={index}
                textTransform="uppercase"
                fontSize={["8", "16"]}
                letterSpacing="wide"
                color="teal.800"
              >
                {elem?.sportDetails}
              </Center>

{
  elem?.image ?(
    <Center>
                <a
                  href={elem.url}
                  target="_blank"
                  style={{
                    fontSize: "18px",
                    fontWeight: "800",
                    textDecoration: "none", // Remove underline from the link
                    display: "inline-block", // Ensure the button takes only the necessary width
                    padding: "10px 20px", // Adjust padding to your liking
                    marginTop: '16px',
                    backgroundColor: "#007bff", // Button background color
                    color: "#fff", // Button text color
                    borderRadius: "5px", // Add rounded corners to the button
                    cursor: "pointer", // Change cursor to indicate it's clickable
                    position: 'relative'
                  }}
                >
                  {elem.buttonHeading}<span> 👆</span>
                </a>

              </Center>
              ):("")


}

            </>
          ))}

        </SimpleGrid>
      </div>

    </>
  )

}
export default Inspiration