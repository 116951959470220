import React, { useState } from "react";

const HeaderTop = () => {
  // const [hash, setHash] = useState("");

  // const handleLinkClick = (event) => {
  //   event.preventDefault();

  //   const targetHash = event.target.hash;
  //   const targetElement = document.querySelector(targetHash);

  //   if (targetElement) {
  //     window.scrollTo({
  //       top: targetElement.offsetTop - 0,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  var manual_or_random = "manual"; //"manual" or "random"
  var randomsetting = "3 days"; //"eachtime", "sessiononly", or "x days (replace x with desired integer)". Only applicable if mode is random.

  //////No need to edit beyond here//////////////

  function getCookie(Name) {
    var re = new RegExp(Name + "=[^;]+", "i"); //construct RE to search for target name/value pair
    if (document.cookie.match(re))
      //if cookie found
      return document.cookie.match(re)[0].split("=")[1]; //return its value
    return null;
  }

  function setCookie(name, value, days) {
    var expireDate = new Date();
    //set "expstring" to either future or past date, to set or delete cookie, respectively
    var expstring =
      typeof days != "undefined"
        ? expireDate.setDate(expireDate.getDate() + parseInt(days))
        : expireDate.setDate(expireDate.getDate() - 5);
    document.cookie =
      name + "=" + value + "; expires=" + expireDate.toGMTString() + "; path=/";
  }

  function deleteCookie(name) {
    setCookie(name, "moot");
  }

  function setStylesheet(title, randomize) {
    //Main stylesheet switcher function. Second parameter if defined causes a random alternate stylesheet (including none) to be enabled
    var i,
      cacheobj,
      altsheets = [""];
    for (i = 0; (cacheobj = document.getElementsByTagName("link")[i]); i++) {
      if (
        cacheobj.getAttribute("rel").toLowerCase() == "alternate stylesheet" &&
        cacheobj.getAttribute("title")
      ) {
        //if this is an alternate stylesheet with title
        cacheobj.disabled = true;
        altsheets.push(cacheobj); //store reference to alt stylesheets inside array
        if (cacheobj.getAttribute("title") == title)
          //enable alternate stylesheet with title that matches parameter
          cacheobj.disabled = false; //enable chosen style sheet
      }
    }
    if (typeof randomize != "undefined") {
      //if second paramter is defined, randomly enable an alt style sheet (includes non)
      var randomnumber = Math.floor(Math.random() * altsheets.length);
      altsheets[randomnumber].disabled = false;
    }
    return typeof randomize != "undefined" && altsheets[randomnumber] != ""
      ? altsheets[randomnumber].getAttribute("title")
      : ""; //if in "random" mode, return "title" of randomly enabled alt stylesheet
  }

  function chooseStyle(styletitle, days) {
    //Interface function to switch style sheets plus save "title" attr of selected stylesheet to cookie
    if (document.getElementById) {
      setStylesheet(styletitle);
      setCookie("mysheet", styletitle, days);
    }
  }

  /*----------font size change script--------------*/
  //Add font size
  var min = 13;
  var max = 19;
  var reset = 15;

  function increaseFontSize() {
    var p = document.getElementsByTagName("body");
    for (let i = 0; i < p.length; i++) {
      if (p[i].style.fontSize) {
        var s = parseInt(p[i].style.fontSize.replace("px", ""));
      } else {
        var s = 15;
      }
      if (s != max) {
        s += 1;
      }
      p[i].style.fontSize = s + "px";
    }
  }

  function decreaseFontSize() {
    var p = document.getElementsByTagName("body");
    for (let i = 0; i < p.length; i++) {
      if (p[i].style.fontSize) {
        var s = parseInt(p[i].style.fontSize.replace("px", ""));
      } else {
        var s = 15;
      }
      if (s != min) {
        s -= 1;
      }
      p[i].style.fontSize = s + "px";
    }
  }

  function reSetFontSize() {
    var p = document.getElementsByTagName("body");
    for (let i = 0; i < p.length; i++) {
      if (p[i].style.fontSize) {
        var s = parseInt(p[i].style.fontSize.replace("px", ""));
      } else {
        var s = 15;
      }
      if (s != reset) {
        s = 15;
      }
      p[i].style.fontSize = s + "px";
    }
  }

  return (
    <>
      <div className="header-toparea">
        <div className="header-topbar" style={{ backgroundColor: "#0c2545;" }}>
          <div className=" d-flex">
            <ul className=" list-unstyled list-inline pull-left topLink top-right-link">
              <li>
                <div className="theme__apply__section">
                  <div className="apply__section">
                    <ul class="contrast topLink1 top-right-link">
                      <li class="night">
                        <a
                          href="javascript:void(0)"
                          onClick={() => chooseStyle("black", 60)}
                          class="blackbox"
                          title="Contrast View"
                        >
                          &nbsp;
                        </a>
                      </li>
                      <li class="day">
                        <a
                          href="javascript:void(0)"
                          onClick={() => chooseStyle("none", 60)}
                          class="whitebox"
                          title="Normal View"
                        >
                          &nbsp;
                        </a>
                      </li>
                    </ul>

                    {/* ---------------------------------------------------- ends the mode ------------------------------------------------------ */}

                    {/* ---------------------------------------------------text increase and decrease mode start issue  */}
                    <ul className="topLink top-right-link topLink1">
                      <li className="textsize screenReader" tabIndex="5">
                        <a
                          href="#"
                          onClick={increaseFontSize}
                          className="decrease-font screenreader"
                          title="Increase Font Size"
                          tabIndex="3"
                        >
                          A+
                        </a>
                        <a
                          href="#"
                          onClick={reSetFontSize}
                          className="reset-font"
                          title="Normal Font Size"
                        >
                          A
                        </a>

                        <a
                          href="#"
                          onClick={decreaseFontSize}
                          className="increase-font"
                          title="Decrease Font Size"
                        >
                          A-
                        </a>
                      </li>

                      <li
                        className="textsize tweeter screenReader"
                        tabIndex="7"
                      ></li>

                      <li
                        className="textsize instagram screenReader"
                        tabIndex="7"
                      ></li>

                      <li
                        className="textsize youtube screenReader"
                        tabIndex="7"
                      ></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="screenReader link-dnone" tabIndex="0">
                <a href="/sitemap">साइट मैप</a>
              </li>
              {/* <li className="screenReader link-dnone" tabIndex="1">
                <a href="/" title="Download">
                  स्क्रीन रीडर एक्सेस
                </a>
              </li> */}
              <li className="screenReader link-dnone" tabIndex="2">
                <a href="/contactus" title="Feedback">
                  प्रतिक्रिया
                </a>
              </li>
              <li className="screenReader link-dnone" tabIndex="3">
                <a
                  href="https://rtionline.gov.in/"
                  title="RTI"
                  target="_blank"
                  rel="noreferrer"
                >
                  आर.टी.आई
                </a>
              </li>
              <li className="screenReader link-dnone" tabIndex="4">
                <a
                  href="#content"
                  className="skiptocontent"
                  title="Skip to Content"
                // onClick={handleLinkClick}
                >
                  मुख्य पृष्ठ पर जाएं
                </a>
              </li>
            </ul>
            <div>
              <ul className="list-unstyled list-inline login-widget topLink top-right-link ">
                <li className="ml-auto dropdown position-relative">
                  <a
                    className="dropdown-toggle bg-headcont position-absolute top-0 start-0"
                    data-toggle="dropdown"
                    href="#"
                  >
                    वार्षिक कैलेंडर
                    <span className="caret"></span>
                  </a>

                  <ul className="dropdown-menu login service top-dropdown">
                    <li className=" bg-headcont">
                      <a
                        href="https://calendar.google.com/calendar/u/0/embed?src=104c8f6657a10af9c6323c32896270a1302d352b69099b47cbae1bc90e030fc7@group.calendar.google.com"
                        target="_blank"
                      >
                        वार्षिक कैलेंडर (2024-2025)
                      </a>
                    </li>

                    <li className=" bg-headcont">
                      <a
                        href="/img/calender/bihar_sports_calender_2023-24.pdf"
                        target="_blank"
                      >
                        वार्षिक कैलेंडर (2023-2024)
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="ml-auto dropdown position-relative">
                  <a
                    className="dropdown-toggle bg-headcont position-absolute top-0 start-0"
                    data-toggle="dropdown"
                    href="#"
                  >
                    ऑनलाइन सेवा
                    <span className="caret"></span>
                  </a>

                  <ul className="dropdown-menu login service top-dropdown ">
                    <li className="  bg-headcont">
                      <a
                        href="https://online.bih.nic.in/GADREC/(S(ncetcr12rl4ezt2gkg2dab3r))/Default.aspx"
                        target="_blank"
                      >
                        सीधी नियुक्ति नियमावली हेतु दावा/आपत्ति
                      </a>
                    </li>

                    {/* <li className=" bg-headcont">
                      <a href="/" target="_blank">
                        लॉग इन
                      </a>
                    </li> */}

                    {/* <li className="">
                    <a href="#" target="_blank">
                      खेल सम्मान समारोह के लिए ऑनलाइन आवदेन
                    </a>
                  </li> */}

                    <li className=" bg-headcont">
                      <a
                        href="img/registration_for_khelo_india_center.pdf"
                        target="_blank"
                      >
                        खेलो इंडिया सेंटर के लिए आवेदन
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            {/*<li>
         	<a href="https://portal.sportsodisha.gov.in/Talent/Choose" className="talent-patch" target="_blank">Register for Talent Scouting</a>
                    </li> */}
            {/* <div className="click-link"> */}

            {/*  <div className="gradient_animate apply__animate"> */}
            {/* <a href="odisha-talent-adoption-program" target="_blank" title="Odisha Talent Adoption Program" style="color:white;">Odisha Talent Adoption Program</a>*/}
            {/* <a href="https://samsodisha.gov.in/" target="_blank" title="Online application for B.P.Ed. & M.P.Ed. Courses" style="color:white;">Online Application for B.P.Ed. & M.P.Ed. Courses</a> */}
            {/* <a href="https://portal.sportsodisha.gov.in/Stadium/StadiumLogin" target="_blank" title="Apply for Kalinga Stadium Entry Pass" style="color:white;"> Apply for Kalinga Stadium Entry Pass</a> */}
            {/* <a href="https://portal.sportsodisha.gov.in/Stadium/StadiumLogin" target="_blank" title="Apply for Kalinga Stadium Entry Pass" style="color:white;"> Apply for Kalinga Stadium Entry Pass</a> */}
            {/*
				</div>
			</div> */}
            {/* <!--  </li> --> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default HeaderTop;
