import React from "react";
import Styled from './NewInfoButton.module.css'

const NewInfoButton = () => {
    return (
        // <a href="img/notices/Final_List_02_11_2023.pdf" target="_blank">
        //     <div className={`${Styled.circle}`} style={{textAlign:'center'}}>
        //     <b>Final Merit List of "Medal Lao Naukri Pao"</b>
        //     </div>
        // </a>
        <a href="https://gad-ospr.bihar.gov.in/(S(gwhdt5c21xlqx4ih40dj2jck))/Default.aspx" target="_blank">
            <div
                className={`${Styled.circle}`}
                style={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    fontweight: "800",
                }}
            >
                Online Application for the Selection of Outstanding Players (for the Year 2024)
            </div>
        </a>

    );
}

export default NewInfoButton