import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
// import styles from '../styles/gallery.module.css'
import styles from "../../components/Gallery.module.css";
import api from "../../utils/ApiMethod";
import ServiceCard from "../../components/ServiceCard";

// import Image from 'next/image'
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  VStack,
  Avatar,
  GridItem,
  Grid,
  Image,
  useColorModeValue,
  SimpleGrid,
  keyframes,
  Flex,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useParams } from 'react-router-dom';

const GalleryPage = (props) => {
  const { eventtype } = useParams();
  const [filterData, setFilterData] = useState([]);


  useEffect(() => {
    const getFilterData = async () => {
        try {
            const response = await api.fetchData(`/api/eventname/${eventtype}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            setFilterData(response);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    // Include eventtype as a dependency for useEffect
    if (eventtype) {
        getFilterData();
    }
    else{
      setFilterData([]);

    }
}, [eventtype]); // eventtype added as a dependency



useEffect(() => { const data = window})


  return (
    <>
      <Breadcrumb
        pageName="Gallery"
        pageTitle="gallery"
        id="#gallery"
        img_url="./img/Gallery-desktop.jpg"
        mobile_banner="./img/Gallery-mobile.jpg"
      />
      <Heading p={5} fontSize="50px">
        तस्वीरें
      </Heading>

      <Center
        position="relative"
        alignContent={"center"}
        alignItems={"center"}
        pt={"20px"}
        pb={"50px"}
      >
        <Grid
          templateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr"]}
          alignItems={"center"}
        >
          {filterData && filterData?.map((item, index) => (
            <Box
              key={index}
              pt="10%"
              pl={"16%"}
              transition="transform 0.3s ease-in-out"
              _hover={{ transform: "scale(1.1)" }}
            >
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  translateX: index % 2 === 0 ? -50 : 50,
                  translateY: -50,
                }}
                whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                transition={{ duration: 0.3, delay: index * 0.2 }}
              >
                <a href={`https://api.biharsports.org/${item?.imageUrl?.replace(
                  "public/images/",
                  "images/"
                )}`}
                  target="_blank">
                  <Image
                    src={`https://api.biharsports.org/${item?.imageUrl?.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt="img"
                    borderRadius="lg"
                    height="90%"
                    width="90%"
                  />
                </a>

              </motion.div>
            </Box>
          ))}
        </Grid>
      </Center>
    </>
  )
}
export default GalleryPage