import React, { useState, useEffect } from "react";

import {
    Box,
    Center,
    useColorModeValue,
    Heading,
    Text,
    Stack,
    Image,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import api from "../../utils/ApiMethod";

const Kss = () => {

    const [commingData, setCommingData] = useState([])

    const handleFetchData = async () => {
        try {
            // const data = await api.fetchData("/get/kss");
            const data = await api.fetchData("/api/kss"); // url for db.json
            setCommingData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        handleFetchData();
    }, []);


    return (
        <div>
            {/* Khelm-Samman-Samaroh-desktop.jpg */}
            <Breadcrumb
                pageName="Khel Samman Samaroh"
                pageTitle="Khel Samman Samaroh"
                id="#kss"
                // img_url="./img/Khelm-Samman-Samaroh-desktop.jpg"
                // mobile_banner="./img/Khelm-Samman-Samaroh-mobile.jpg"
                img_url={`https://api.biharsports.org/${commingData[0]?.bannerUrl?.replace(
                    "public/images/",
                    "images/"
                )}`}
                mobile_banner={`https://api.biharsports.org/${commingData[0]?.mobileBannerUrl?.replace(
                    "public/images/",
                    "images/"
                )}`}
            />
            <Box
                p={4}
                display={{ md: "flex" }}
                h="70vh"
                width="100%"
                px={[4, 10, 20]}
                py={[4, 10, 20]}
            >
                <Box flexShrink={0}>
                    <Image
                        borderRadius="lg"
                        // width={{ md: 100 }}
                        px={[5, 10, 20]}
                        // src="https://bssadev.brandradiator.in/img/event-1kss.jpg"
                        src={`https://api.biharsports.org/${commingData[0]?.pageImage?.replace(
                            "public/images/",
                            "images/"
                        )}`}
                        alt="Khel samman samaroh"
                        h="90%"
                        w="100%"
                    />
                </Box>

                <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
                    <Text
                        fontWeight="bold"
                        textTransform="uppercase"
                        fontSize={["18", "42"]}
                        letterSpacing="wide"
                        color="teal.800"
                        px={[4, 10, 20]}
                    >
                        {commingData[0]?.heading}
                    </Text>


                    {commingData?.map((elem, index) => (
                        <div key={index}>
                            <Text
                                fontWeight="bold"
                                textTransform="uppercase"
                                fontSize={["8", "16"]}
                                letterSpacing="wide"
                                color="teal.800"
                                textAlign={'left'}
                            >
                                {elem?.paragraph}
                            </Text>
                        </div>
                    ))}

                </Box>
            </Box>
        </div>
    );
};
export default Kss;
