import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
// import styles from '../styles/gallery.module.css'
import styles from "../../components/Gallery.module.css";
import api from "../../utils/ApiMethod";
import ServiceCard from "../../components/ServiceCard";

// import Image from 'next/image'
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  VStack,
  Avatar,
  GridItem,
  Grid,
  Image,
  useColorModeValue,
  SimpleGrid,
  keyframes,
  Flex,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Link } from "react-router-dom";

// import key frame from chakra ui
const animation = keyframes`
from {
 background:inherit
}
to{
    background:gray.100


}
`;

// ------------------------------- hello--------------------------------

// -----------------------------------------end

const PressGallery = () => {
  const myAnimation = `${animation} infinite 1s`;

  const [galleryData, setGalleryData] = useState([]);
  const [eventType, setEventType] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [uniqueName, setUniqueNames] = useState([]);

  const radialGradient = "radial-gradient(circle, #ff9900, #ff6600)";
  const radialGradient2 = "radial-gradient(circle, #ffefba, #ffffff);";
  const { eventName, images } = uniqueName;

  const getFilterData = async (requestData) => {
    // console.log('unique name', requestData, 'uniqueName', uniqueName)
    try {
      const response = await api.fetchData(`/api/eventname/${requestData}`, {
        method: "GET",  // Change the method to GET
        headers: {
          "Content-Type": "application/json"
        }
      });
      setFilterData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const getUniqueNames = async () => {
    try {
      const data = await api.fetchData("/api/uniqueevent");
      setUniqueNames(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/gallery");
      setGalleryData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
    getUniqueNames();
  }, []);

  const containerVariants = {
    hover: {
      initial: {
        opacity: 0.2,
      },
      animate: {
        opacity: 1,
      },
    },
  };

  return (
    <div>
      <Breadcrumb
        pageName="Gallery"
        pageTitle="gallery"
        id="#gallery"
        img_url="./img/Gallery-desktop.jpg"
        mobile_banner="./img/Gallery-mobile.jpg"
      />
      <Heading p={5} fontSize="50px">
        तस्वीरें
      </Heading>
      {/* ------------------------------------horizontal Line---------------------------------------------------- */}
      <div
        style={{
          height: "4px",
          width: "100%",
          backgroundColor: " rgb(8, 44, 44)",
          paddingBottom: "3px",
          margin: '2px'
        }}
      ></div>
      {/* ------------------------------------------------------------------------------------------------------- */}
      {filterData.length === 0 ? (
        <div style={{
          display: 'flex', display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <SimpleGrid columns={[1, 2, 3, 5]} spacing="20px" m="auto" width="90%">
            {uniqueName &&
              uniqueName.map((data, index) => (

                <Link to={`/gallerypage/${data.eventType}`}>
                <motion.div
                  key={index}
                  initial={{
                    opacity: 0,
                    translateX: index % 2 === 0 ? -50 : 50,
                    translateY: -50,
                  }}
                  whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.2 }}
                >
                  <button style={{
                    padding: "4px 4px",
                    fontSize: "10px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                    key={index}
                    onClick={() => getFilterData(data?.eventType)}>
                    <ServiceCard data={data} />
                  </button>
                </motion.div>
                </Link>
              ))}
          </SimpleGrid>
        </div>
      ) :
        <Center
          position="relative"
          alignContent={"center"}
          alignItems={"center"}
          pt={"20px"}
          pb={"50px"}
        >
          <Grid
            templateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr"]}
            alignItems={"center"}
          >
            {filterData.map((item, index) => (
              <Box
                key={index}
                pt="10%"
                pl={"16%"}
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.1)" }}
              >
                <motion.div
                  key={index}
                  initial={{
                    opacity: 0,
                    translateX: index % 2 === 0 ? -50 : 50,
                    translateY: -50,
                  }}
                  whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.2 }}
                >
                  <a href={`https://api.biharsports.org/${item?.imageUrl?.replace(
                    "public/images/",
                    "images/"
                  )}`} target="_blank">
                    <Image
                      src={`https://api.biharsports.org/${item?.imageUrl?.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt="img"
                      borderRadius="lg"
                      height="90%"
                      width="90%"
                    />
                  </a>

                </motion.div>
              </Box>
            ))}
          </Grid>
        </Center>}
      {/* =================ends ======================= */}
    </div>
  );
};

export default PressGallery;
