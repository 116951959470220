import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Gift.module.css";

import { useMediaQuery } from "@chakra-ui/react";

// Import Swiper styles
// import "swiper/css";
import "swiper/css/navigation";
// Import Swiper React components
// import { delay, motion, useAnimation } from 'framer-motion';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
// import './styles.css';

// import required modules
import { Navigation } from "swiper/modules";
import Akash from "../assets/images/Akash Kumar.jpeg";
import Anuj from "../assets/images/Anuj Kumar.jpeg";
import NishiAndSoni from "../assets/images/Nishi and soni.jpeg";
import PromodSingh from "../assets/images/Promod singh.jpeg";
import RugbyGirls from "../assets/images/rugby girls.jpeg";
import Anjani from "../assets/images/Anjani.jpeg";
import ShibuKumar from "../assets/images/Shibu Kumar.jpeg";
import api from "../utils/ApiMethod";

function Gift() {
  const navigationArrowStyles = {
    "&.swiper-button-next::before, &.swiper-button-prev::before": {
      color: "red", // Change this to your desired color
    },
  };

  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");

  const slidesPerView = isSmallScreen ? 1 : 1;

  const [risingStarList, setRisingStarList] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("get/risingstar");
      setRisingStarList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <Swiper
        autoplay={{
          delay: 2000,
        }}
        // autoplay={{
        //   delay: 2000, // Autoplay delay in milliseconds
        //   disableOnInteraction: true, // Autoplay stops even after user interaction
        // }}
        slidesPerView={slidesPerView}
        // rewind={true}
        navigation
        style={navigationArrowStyles}
        modules={[Navigation]}
        // modules={[FreeMode, Pagination]}
        loop={true}
        spaceBetween={10}
        bgGradient={[
          "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
          //   'linear(to-t, blue.200, teal.500)',
          //   'linear(to-b, orange.100, purple.300)',
        ]}
        className={styles.mySwiper}
        // style={{"height" : "600px", border:"20px solid lightgray", margin:"0px"}}
      >
        {risingStarList &&
          risingStarList.map((elem, index) => (
            <SwiperSlide key={elem?._id}>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div
                    className="award__img"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <img
                      src={`https://api.biharsports.org/${elem?.imageUrl?.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.playerName}
                    />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    {/* <h4> आकाश कुमार</h4> */}
                    <h4>{elem?.playerName}</h4>
                    {/* <h5>फेंसिंग</h5> */}
                    <h5>{elem?.game}</h5>
                    {/* <h5>(2023)</h5> */}
                    <h5>{elem?.year}</h5>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
}
export default Gift;
