import React, { useEffect } from "react";
import Swiper from "swiper";
// import "swiper/css/swiper.min.css"; // Import Swiper styles
import { delay, motion, useAnimation } from "framer-motion";
import Shreyeasi_Singh from "../assets/images/Shreyashi_Singh-new.jpeg";

import Styles from "../styles/BasicStructureTwo.module.css";
import Gift from "./Gift";

const BasicStructureTwo = () => {
  useEffect(() => {
    var swiper = new Swiper(".whatsNewCarousel .swiper-container", {
      slidesPerView: 3,
      slidesPerColumn: 2,
      grid: {
        rows: 4,
      },
      spaceBetween: 30,
      grabCursor: true,
      navigation: {
        nextEl: ".award__arrow .swiper-button-next",
        prevEl: ".award__arrow .swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 10,
          pagination: {
            el: "#AwardPagination",
            dynamicBullets: true,
            clickable: true,
          },
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1900: {
          slidesPerView: 3,
          grid: {
            rows: 2,
          },
          spaceBetween: 20,
        },
      },
    });
  }, []);

  return (
    // <div>
    <div className="container">
      <div className="row">
        <motion.div
          className="col-sm-12 col-md-4 col-lg-4 news-bg"
          initial={{
            translateX: "-50vh",
          }}
          whileInView={{ translateX: "0" }}
          // animate={{opacity:1, translateX:0}}
          transition={{ duration: 0.5 }}
        >
          <h4 className="section-heading" style={{ color: "#fff" }}>
          Basic Infrastructures
          </h4>
          <div className="infrastructure-Carousel ">
            <div
              id="infrastructure-carousel"
              className="carousel slide img-animate  infra-carousal-slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div
                  className="item active"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className={Styles.video_container}>
                    <video
                      autoPlay
                      muted
                      loop
                      // style={{ width: "500px", height: "200px" }}
                    >
                      <source
                        src="img/patliputra khel parisar2.mp4"
                        type="video/mp4"
                      />
                    </video>

                    <div className={Styles.overlay_content}>
                      <div className={Styles.carousel_content}>
                        <h3 className={Styles.carousel_title}>
                        Patliputra Sports Complex
                        </h3>
                        <span className="display-block carousel-date">
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>{" "}
                          &nbsp; Patna
                        </span>
                        <p
                          className={Styles.carousel_text}
                          style={{ textAlign: "justify" }}
                        >
                          Patliputra Sports Complex, located in Kankarbagh, Patna, is a multi-purpose stadium. The sports complex was inaugurated on 1st March 2012 along with the inauguration of the first Women's World Cup Kabaddi Championship in India. The Sports Complex is a state-of-the-art complex, with facilities for both indoor and outdoor sports. It is spread over 16 acres.
                        </p>
                      </div>
                      <a
                        href="/infrastructure"
                        className="btn btn-default button-link"
                      >
                        For more information{" "}
                        <i className="bi bi-arrow-up-right-circle"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/*-------------------------------------- sheryasi ------------------------- */}

        <div className="col-sm-4 col-md-4 col-lg-4">
          <div>
            <h4
              className="section-heading d-inline-block mobile-heading"
              style={{ color: "#fff" }}
            >
              Pride of Bihar
            </h4>
          </div>

          <div className="whatsNewCarousel__card">
            <div className="award__img" style={{width:"100%" ,height: "100%",}}>
              <img src={Shreyeasi_Singh} alt="Shreyeasi_Singh" />
            </div>
            <div className="whatsNewCarousel__text" style={{ padding: "12%" }}>
              <h4>Mrs. Shreyasi Singh </h4>
              <h5>Honored with Arjuna Award</h5>
              <h5>Shooting</h5>
            </div>
          </div>

          {/* <div className="h-56px">
          <img src={Shreyeasi_Singh} alt="Shreyeasi_Singh" />
          </div> */}
        </div>

        {/* --------------------------------- gifts------------------------------- */}

        <div className="col-sm-4 col-md-4 col-lg-4">
          <div>
            <h4
              className="section-heading d-inline-block mobile-heading"
              style={{ color: "#fff" }}
            >
             Rising Stars of Bihar
              {/* <!-- <span className="award--title">(Arjuna Award)</span> --> */}
            </h4>

            {/*
              <!-- <Select id="awardselector" className="award_check">
                <option value="Arjuna">स्वर्ण पदक</option>
                <option value="Ratna"> रजत पदक</option>
                <option value="Arjuna">कांस्य पदक</option>
                <option value="Ratna"> खेल रत्न पुरस्कार</option>
              </Select> --> */}
          </div>
          <div className="h-56px">
            <Gift />
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default BasicStructureTwo;
