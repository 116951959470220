import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import style from "./Athlite.module.css";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function SepakTakra() {
  return (
    <>
      <Breadcrumb
        pageName={""}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />

      <div>
        <h2 className={`${style.title}`}>
          <strong>67th National School Games (2023-24)</strong> Sepak Takraw Championship
        </h2>
      </div>

      <div className={`container ${style.athlite_details}`}>
        <div className={`${style.athlite_description}`}>
          <p>
          Organization of Sepak Takraw Championship Under-17 (Boys/Girls category) of 67th National School Games 2023
          </p>
          <p>
          It is being organized from 3rd January 2024 to 7th January 2024 at the Patliputra Sports Complex located in Kankarbagh, Patna, the capital of Bihar.

          </p>
          <p style={{ color: `rgb(30, 30, 90)`, fontWeight: "600" }}>
          The last date for entry of players in Sepak Takraw Championship has been fixed as 24th December 2023. Along with this, the reporting date for the players participating in this championship has been fixed as 2nd January 2024.
          </p>
          <br />
          <p>Control Room Address: Patliputra Sports Complex, Kankarbagh, Patna</p>
          <p>Name of in-charge of control room: Gajendra Sahni</p>

          <p>Mobile Number: 7483241475</p>
          <br />
          <p>Name of Nodal Officer: Shri Jai Narayan Kumar, District Sports Officer</p>
          <p>Mobile Number: 9661261352</p>
          <br />
        </div>
        <div className={`${style.athlite_img}`}>
          <img
            src="./img/net_sgfi.png"
            alt=""
            style={{ height: "auto", width: "30rem", padding: "2rem" }}
          />
          <p>Sepak Takraw</p>

          <a href="img/sepaktakraw.pdf" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
          View Details ↗
        </Button>
      </a>
        </div>
      </div>
      <a href="http://www.sgfi.org.in/" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
        Click for more information ↗
        </Button>
      </a>
    </>
  );
}

export default SepakTakra;
