import React, { useEffect, useState } from "react";
import api from "../utils/ApiMethod";

export const WhatNew = () => {
  const [isPaused, setPaused] = useState(false);
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/get/marquees");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleMouseOver = () => {
    setPaused(true);
  };

  const handleMouseOut = () => {
    setPaused(false);
  };

  return (
    <div>
      {/* these css is written in  common.css*/}
      <div className="whatsNew-section">
        <div className="container">
          <div className="whatsNew-content">
            <h4
              className="whatsnew-heading"
              style={{ backgroundColor: "#016938" }}
            >
              <a
                href="whatsNewinner"
                className="whatsnew-link"
                title="Click here to View What's New Details "
              >
                Highlights
              </a>
            </h4>

            <div
              className="whatsNew-list-inner"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              {data.length > 0 && (
                <marquee
                  scrollamount={isPaused ? 0 : 5} // Adjust the scroll speed as needed
                >
                  <ul
                    className="whatsNew-list"
                    style={{ alignItems: "center" }}
                  >
                    {data.map((elem, index) => (
                      <li key={index}>
                        <a
                          href={elem.documentlnk ? elem.documentlnk : "#"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>
                            {elem?.newest === true ? (
                              <img
                                src="img/new-blink-image.gif"
                                style={{
                                  height: "35px",
                                  width: "35px",
                                  display: "inline",
                                }}
                                alt="blink"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          {elem?.noticeTitle}
                          <span style={{ margin: "0px 12px" }}></span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </marquee>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
