import React, { useState, useEffect } from "react";
import styled from "../tenders/style.module.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import api from "../../utils/ApiMethod";
// import 'bootstrap/dist/css/bootstrap.min.css';

const GameDetailsPage = () => {
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("http://localhost:5000/api/latestinformation");
      const res = await api.fetchData("https://bssa-db.vercel.app/nationalgames");
      // const res = await api.fetchData("http://localhost:4000/nationalgames");

      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <div className=" mt-5">
        <h4
          className="section-heading"
          style={{
            color: "black",
            fontWeight: "800",
            fontSize: "18px",
            marginTop: "40px",
            textAlign: "justify",
          }}
        >
          Bihar's performance in 37th National Games 2023
        </h4>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="align-middle" rowspan="2">
                  Sl.No.{" "}
                </th>
                <th className="align-middle text-align: center" rowspan="2">
                  Game
                </th>
                <th rowspan="2">Gender</th>
                <th rowspan="2">Date Of Competition</th>
                <th rowspan="2">Venue</th>
                <th rowspan="2">Bihar's performance</th>
                <th rowspan="2">Medal</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <>
                  <tr>
                    <td
                      className={`align-middle" ${styled.tablecell} `}
                      rowspan="2"
                    >
                      {index + 1}
                    </td>

                    <td
                      className="align-middle "
                      style={{ textAlign: "center", alignItems: "center" }}
                      rowspan="2"
                    >
                      {item?.GamesName}
                    </td>

                    {item.FemaleGameHeldAt ? (
                      <>
                        <td>{item?.genderOne ? item?.genderOne : "__"}</td>

                        <td>
                          {item?.FemaleGameHeldAt
                            ? item?.FemaleGameHeldAt
                            : "__"}
                        </td>

                        <td>{item?.Femalevenue ? item?.Femalevenue : "__"}</td>

                        <td>
                          {item?.FemalePerformance
                            ? item?.FemalePerformance
                            : "__"}
                        </td>

                        <td>{item?.femaleMedal ? item?.femaleMedal : "__"}</td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>

                  <tr>
                    {item.MaleGamehealdAt ? (
                      <>
                        <td>{item?.genderTwo ? item?.genderTwo : "__"}</td>

                        <td>
                          {item?.MaleGamehealdAt ? item?.MaleGamehealdAt : "__"}
                        </td>

                        <td>{item?.Malevenue ? item?.Malevenue : "__"}</td>

                        <td>
                          {item?.MalePerformance ? item?.MalePerformance : "__"}
                        </td>

                        <td>{item?.maleMedal ? item?.maleMedal : "__"}</td>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default GameDetailsPage;
