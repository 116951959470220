import React, { useState, useEffect } from "react";
import {
  SimpleGrid,
  Square,
  Text,
  Box,
  Center,
  Container,
} from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import Breadcrumb from "../../components/common/Breadcrumb";

const Udaan = () => {
  const [commingData, setCommingData] = useState([]);

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("/get/udaan");
      const data = await api.fetchData("/api/udaan"); // url for db.json
      setCommingData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>

       
<Breadcrumb
        pageName="udaan"
        pageTitle="udaan"
        id="#udaan"
        // img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        // mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"
        img_url={`https://api.biharsports.org/${commingData[0]?.bannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
        mobile_banner={`https://api.biharsports.org/${commingData[0]?.mobileBannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
      />
      <div
        style={{
          backgroundImage: "linear-gradient(to bottom, #0a3272, white)",
        }}
      >
        <Text
          // fontWeight="bold"
          textTransform="uppercase"
          p={"4px"}
          pb={"12px"}
          // align={"right"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          color="black"
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight="extrabold"
        >
          {/* उड़ान */}
          {commingData[0]?.udaanItem[0]?.sportName}
        </Text>
        {commingData[0]?.udaanItem.map((elem, index) => (
          <SimpleGrid columns={1} spacingX={8} mb={7} mt={5}>
            <Center
              as={"text"}
              h="100px"
              textAlign={"left"}
              p="3%"
              fontWeight="bold"
              textTransform="uppercase"
              fontSize={["8", "16"]}
              letterSpacing="wide"
              color="teal.800"
            >
              {elem.sportDetails}
            </Center>
          </SimpleGrid>
        ))}
      </div>
    </>
  );
};
export default Udaan;
