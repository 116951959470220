import React from "react";

function Breadcrumb({ pageName, pageTitle, id, img_url, mobile_banner }) {

  return (
    <>
      <section className="bannerSection">
        <div
          id="bannerSection-Carousel"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="item active">
              <img src={mobile_banner} alt="banner-img" className="mob__banner" />
              <img src={img_url} alt="banner-img" className="other__banner" />
              <div className="container">
                <div className="bannercarousel carousel-caption">
                  <div className="carousel-caption__area animated bounceInRight slower">
                    <div className="carousel-caption__areaInner">
                      <h2 className="carousel-caption__areaTitle">
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Breadcrumb;
