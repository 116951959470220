import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const EklavyaKheloCentres = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Eklavya Khelo India Centres"
        pageTitle="Eklavya Khelo India Centres"
        id="#Eklavya-Khelo-India-Centres"
        img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1> Eklavya Center Khelo India Centers</h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  Eklavya Center
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              In order to provide best training to the players in the state and
              to develop sports related talent among them, the work of
              construction of sports centers is in progress in many districts of
              the state by the Government of Bihar. After the construction of
              about 41 Eklavya centers across the state, sports and players in
              the state is bound to take leap and bounds. The players of Bihar
              will be able to make the nation and the state proud across the
              world by the help of better training and opportunity to perform on
              a bigger platform.
              <span style={{ color: "#ffd53f" }}>
                <a href="img/eklavya_Kendra.pdf" target="_blank">
                  {" "}
                  View list of Eklavya centers
                  <i
                    class="fas fa-file"
                    style={{ padding: "8px", color: "#ffd53f" }}
                  ></i>
                </a>
              </span>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  Khelo India Centers
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              The Government of Bihar is working at a fast pace to promote
              sports in the state. Bihar State Sports Authority is constructing
              sports centers on a large scale along with supplying trainers in
              33 districts of the state to hone the sports talent among the
              young players of the state and make them capable of national and
              international competitions. Under this initiative, construction of
              sports centers is going on in Patna, Muzaffarpur, Araria,
              Jehanabad, West Champaran, Rohtas, Samastipur, Lakhisarai,
              Madhepura, Kishanganj, Saharsa, Aurangabad, Gaya, Bhagalpur,
              Nalanda, Gopalganj, Bhojpur, Shekhpura, Sitamarhi, Begusarai,
              Buxar, Kaimur, Jamui, Chhapra, Supaul, Khagaria, Siwan, Katihar,
              Vaishali, Munger, Madhubani, East Champaran and Darbhanga.
              <span style={{ color: "#ffd53f" }}>
                <a href="img/Kic.pdf" target="_blank">
                  {" "}
                  View list of Khelo India centers
                  <i
                    class="fas fa-file"
                    style={{ padding: "8px", color: "#ffd53f" }}
                  ></i>
                </a>
              </span>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>
    </div>
  );
};
export default EklavyaKheloCentres;
