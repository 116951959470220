import React from "react";
import styles from "../styles/servicecard.module.css";

const ServiceCard = ({ ...props }) => {  // props coming from ServiceNew
  //   console.log(data.description, "saaddd");
  const { title, description, image ,url,eventType} = props.data;

  // console.log('from service card',eventType, image)



  return (
    <div>
      {/* <div className={styles.note}>
        Based on this
        <a href="https://dribbble.com/shots/3127773-Event-Card" target="_blank">
          dribbble
        </a>
      </div> */}
        <a href={url}>
      <article className={styles.card}>
        <div
          className={styles.thumb}
          style={{
            backgroundImage:
              // 'url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/new-york-city.png")',
              // `url("${image}")`,
              // `url(https://api.biharsports.org/${image.replace("public/images/","images/")})`,
              `url(https://api.biharsports.org/${image?.replace("public/images/","images/")})`,


            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
        </div>

        <div className={styles.infos}>
          <h2 className={styles.title}>
            {eventType ? eventType :title} <br />
            {/* <span className={styles.flag}></span> */}
          </h2>
          <h3 className={styles.details}> {description}</h3>
          {/* <h3 className={styles.seats}>seats remaining: 2</h3> */}
          {/* <p className={styles.txt}>
            Join us for our Live Infinity Session in beautiful New York City.
            This is a 3 day intensive workshop where you'll learn how to become
            a better version of...
          </p> */}
          <h3 className={styles.details}>{eventType?'view More >>':'Description +'}</h3>
        </div>
      </article>
      </a>
    </div>
  );
};

export default ServiceCard;
