import React,{useState} from "react";
import styled from "../tenders/style.module.css";
import { useStatStyles } from "@chakra-ui/react";
import goldImg from '../../assets/images/gold_icon.png'
import silverImg from '../../assets/images/silver_icon.png'
import bronzeImg from '../../assets/images/bronze_icon.png'

const MedalTally = () => {

    return (
        <>
            <div className={` ${styled.medal} `} style={{  width: "100%" }}>
                <div style={{ background: "#08549c", borderRadius: "5px", paddingTop: "20px" }}>
                    <h4
                        style={{
                            color: "white",
                            fontWeight: "700",
                            fontSize: "100%",
                            // marginTop: "40px",
                            textAlign: "center",
                            marginBottom: "20px"
                        }}
                    >
                        Performance of Bihar players in 37th National Games 2023
                    </h4>
                    <table className="table table-bordered table.responsive table-striped">
                        <thead>
                            <tr className={`table-primary`}>

                                <th className={`${styled.medalth}`}>
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                        <h5 style={{ fontWeight: "800", fontSize: "100%" }}>Gold Medal</h5>
                                        <img src={goldImg} style={{ width: '20%', marginLeft: ".6rem" }} />
                                    </div>
                                </th>

                                <th className={`${styled.medalth}`}>
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                        <h5 style={{ fontWeight: "800", fontSize: "100%" }}>Silver Medal</h5>
                                        <img src={silverImg} style={{ width: '20%', marginLeft: ".6rem" }} />
                                    </div>
                                </th>

                                <th className={`${styled.medalth}`}>
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                        <h5 style={{ fontWeight: "800", fontSize: "100%" }}>Bronze Medal</h5>
                                        <img src={bronzeImg} style={{ width: '20%', marginLeft: ".6rem" }} />
                                    </div>
                                </th>

                                <th className={`${styled.issue}`}>
                                    {/* <h5 style={{topPadding:"10px"}}>कुलपदक</h5> */}
                                    <div className={`${styled.texttt}`}>
                                        <h5 style={{ fontWeight: "800", fontSize: "100%" }}>कुल पदक</h5>
                                        {/* <img src={goldImg} style={{ width: '20%', marginLeft: ".6rem" }} /> */}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={`${styled.medaltd}`}>00

                                </td>
                                <td className={`${styled.medaltd}`}>03</td>
                                <td className={`${styled.medaltd}`}>05</td>
                                <td className={`${styled.medaltd}`}>08</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="container" style={{width:"100%", textAlign:"justify",fontWeight:"700", background:"#00A3A3", padding:"1rem", borderRadius:"10px", marginLeft:"10px"}}>
                  <p><span  style={{color:"red", paddingRight:"10px"}}>*</span>
For archery individual competition, Jai Prakash, Nirbhay Kumar Singh and Shivam Kumar Gupta of Bihar qualified in the Indian round. <span style={{color:"#00308F", paddingLeft:"5rem"}}>Last update</span></p>
            </div>
        </>
    )
}
export default MedalTally

