import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Gift.module.css";
import api from "../utils/ApiMethod";
import { Button, useMediaQuery } from "@chakra-ui/react";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import gallerystyles from "../styles/GalleryThree.module.css";

import { Navigation } from "swiper/modules";

function GalleryCarousal() {

  const category1Images = [
    { name: "Bihar Women's Asian Champions Trophy Rajgir 2024", img: "/img/BWACTRajgir2024/ADIM45653.jpg", id: "9" },
    { name: "Bihar Volleyball League 2024", img: "/img/BiharVolleyballLeague2024/DSC00350.jpg", id: "8" },
    { name: "KSS-2023 at Gyan Bhawan", img: "/img/KSS-2023/DSC_3832.jpg", id: "1" },
    { name: "1st Bihar State Esports Open Championship", img: "/img/1stBiharSEOc/1stbSEoC.jpg", id: "2" },
    { name: "12th National School Chess Championship- 2024", img: "/img/12thNS-CHES24/P1383941.jpg", id: "3" },
    { name: "Bihar School Chess at BSSA", img: "/img/Bihar-School-Chess/DSC_1538.jpg", id: "4" },
    { name: "Bihar Women Kabaddi League", img: "/img/Bihar-Women-Kabaddi-League/JBN00221.jpg", id: "5" },
    { name: "Conclave 2.0", img: "/img/Conclave2.0/DSC_8220.jpg", id: "6" },
    { name: "Dashrath Manjhi", img: "/img/DashrathManjhi/DSC_6676.jpg", id: "7" },
    // { name: "Bihar Women Kabaddi League", img: "/img/Bihar-Women-Kabaddi-League/JBN00221.jpg", id: "8" }
  ];
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/gallery");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const slidesPerView = isSmallScreen ? 1 : 1;

  return (
    <>
      <div
        className={gallerystyles.gallerycontainer}
        style={{
          paddingBottom: "10px",
          //   border: "2px solid #fcb606",
        }}
      >
        <div className={gallerystyles.head}>
          <a href="/GalleryPage" title="Photo Gallery">
            <Button colorScheme="yellow" size="lg" fontSize="16" py={8}>
              Photo Gallery ⨠
            </Button>
          </a>
        </div>

        <Swiper
          autoplay={{
            delay: 2000,
          }}
          // autoplay={{
          //   delay: 2000, // Autoplay delay in milliseconds
          //   disableOnInteraction: true, // Autoplay stops even after user interaction
          // }}
          slidesPerView={slidesPerView}
          // rewind={true}
          navigation={true}
          modules={[Navigation]}
          loop={true}
          spaceBetween={10}
          bgGradient={[
            "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
          ]}
          className={styles.mySwiper}
        >
          {category1Images.map((elem, index) => (
            <SwiperSlide key={index}>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div
                    className="award__img"
                    style={{ width: "100%", height: "370px" }}
                  >
                    <img
                      // src={`https://api.biharsports.org/${elem?.imageUrl?.replace(
                      //   "public/images/",
                      //   "images/"
                      // )}`}
                      src={elem.img}
                      alt="Chandan"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
export default GalleryCarousal;
