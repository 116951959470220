import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const Training = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Sports Training"
        pageTitle="Sports Training"
        id="#Sports-Training"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1> Training </h1>
      </GridItem>
      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  Training
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              To provide better training to the players of Bihar, the Government
              of Bihar is arranging trainers for different sports in the state.
              Along with the construction of sports centers in 33 districts of
              the state, a trainer is being appointed for each center. The
              trainers already present in the state are also providing training
              to the players of the state and upskilling them.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>{" "}
    </div>
  );
};
export default Training;
