import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import api from "../utils/ApiMethod";

const Footer = () => {
  const [footerData, setFooterData] = useState([]);
  const [totalVisitorCount, setTotalVisitor] = useState([]);

  useEffect(() => {
    const handleFetchData = async () => {
      try {
        const data = await api.fetchData("/api/footerpage");
        setFooterData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    handleFetchData();
  }, []);

  // -------------------Total visitor api call-------------------------

  const fetchTotalVisitor = async () => {
    try {
      const data = await api.fetchData("/api/visitor");
      setTotalVisitor(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTotalVisitor();
  }, []);

  // console.log(totalVisitorCount, "Total Visitor");

  //  The empty array [] as the second argument makes this effect run once on component mount.

  // const halfwayIndex = Math.ceil(footerData[0]?.link.length / 2); // Calculate the index to split the array
  // console.log('halfwayIndex-----', halfwayIndex)
  // const filteredLinkItems = footerData[0]?.link.filter(item => item.isDeleted === false);
  // console.log("filteredLinkItems -----------", filteredLinkItems)
  // const firstHalf = filteredLinkItems?.slice(0, halfwayIndex);
  // const secondHalf = filteredLinkItems?.slice(halfwayIndex);
  // // const firstHalf = footerData[0]?.link.slice(0, halfwayIndex);
  // // const secondHalf = footerData[0]?.link.slice(halfwayIndex);
  // console.log("firstHalf -----------", firstHalf)
  // console.log("secondHalf -----------", secondHalf)
  // Filter the items with isDeleted set to false

  const filteredLinkItems = footerData[0]?.link?.filter(
    (item) => item.isDeleted === false
  );
  // Calculate the halfwayIndex based on the filtered array
  const halfwayIndex = Math.ceil(filteredLinkItems?.length / 2);
  // Split the filtered items into two halves
  const firstHalf = filteredLinkItems?.slice(0, halfwayIndex);
  const secondHalf = filteredLinkItems?.slice(halfwayIndex);

  return (
    <>
      <section className="footer-top-bg" style={{ backgroundColor: "#0c2545" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-md-5 col-sm-4 col-xs-12 addrs screenReader"
              tabIndex="87"
            >
              <motion.h4
                className="screenReader"
                tabIndex="88"
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                {/* लिंक {footerData[0]?.text} */}
                {/* <h4 className="screenReader" tabIndex="98">
                 
                </h4> */}
                <p>Links</p>
              </motion.h4>
              {/* ------------------------ under h4 ---------------- */}
              <motion.ul
                className="fotr-lnk"
                initial={{
                  translateX: "10vh",
                }}
                whileInView={{ translateX: "0" }}
                transition={{ duration: 0.5 }}
              >
                {footerData &&
                  firstHalf?.map((item, index) => (
                    <motion.li
                      className="screenReader"
                      key={index}
                      tabIndex="100"
                      whileHover={{
                        scale: 1.1,
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        translateX: "-10px",
                        // paddingLeft: "2%",
                        // paddingRight: "2%",
                        fontSize: "15px",

                        width: "100% !importaint",
                        // backgroundColor:'red'
                      }}
                    >
                      <a href={item?.url}>{item?.title}</a>
                    </motion.li>
                  ))}

                <motion.li
                  className="screenReader"
                  tabIndex="100"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    translateX: "-10px",
                    // paddingLeft: "2%",
                    // paddingRight: "2%",
                    fontSize: "15px",

                    width: "100% !importaint",
                    // backgroundColor:'red'
                  }}
                >
                  <a href="#" style={{ color: "#f05623" }}>
                    Total visitors : {totalVisitorCount?.totalVisitor}
                  </a>
                </motion.li>
              </motion.ul>

              {/* --------------------------- */}

              <motion.ul
                className="fotr-lnk"
                initial={{
                  translateX: "10vh",
                }}
                whileInView={{ translateX: "0" }}
                transition={{ duration: 0.5 }}
              >
                {footerData &&
                  secondHalf?.map((item, index) => (
                    <motion.li
                      className="screenReader"
                      key={index}
                      tabIndex="92"
                      whileHover={{
                        scale: 1.1,
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        translateX: "-10px",
                        // paddingLeft: "2%",
                        // paddingRight: "2%",
                        fontSize: "15px",
                        width: "100%",
                      }}
                    >
                      <a href={item?.url}>{item?.title}</a>
                    </motion.li>
                  ))}
              </motion.ul>

              <motion.ul
                className="fotr-lnk"
                initial={{
                  translateX: "10vh",
                }}
                whileInView={{ translateX: "0" }}
                transition={{ duration: 0.5 }}
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "6%",
                  // paddingLeft: "2%",
                  // paddingRight: "2%",
                }}
              ></motion.ul>
            </div>
            {/* ---------------------------- Link ends================ */}

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 ">
              <h4 className="screenReader" tabIndex="98">
                Address
              </h4>
              <ul className="fotr-lnk">
                {footerData &&
                  footerData[0]?.address
                    ?.filter((item) => item.isDeleted === false) // Filter only non-deleted items
                    ?.map((item, index) => (
                      <div key={index}>
                        <li className="screenReader" tabIndex="99">
                          <strong>{item?.title} </strong>{" "}
                        </li>
                        <li className="screenReader" tabIndex="100">
                          {item?.address}
                          <br />
                          {item?.pin}
                        </li>
                      </div>
                    ))}
                {/* <li className="screenReader" tabIndex="101"> */}
                <motion.li style={{ color: "#f05623" }}>Join us :</motion.li>

                <ul className="social-network social-circle ">
                  {footerData &&
                    footerData[0]?.joinUs
                      ?.filter((item) => item.isDeleted === false) // Filter only non-deleted items
                      ?.map((item, index) => (
                        <li className="screenReader" tabIndex="104" key={index}>
                          <a
                            href={item?.url}
                            target="_blank"
                            rel="noreferrer"
                            className="screenReader icoTwitter hover-rotate"
                            title={item?.title}
                            tabIndex="105"
                            style={{ backgroundColor: item?.bgColor }}
                          >
                            <img
                              src={`https://api.biharsports.org/${item?.image?.replace(
                                "public/images/",
                                "images/"
                              )}`}
                              // src={`http://localhost:5000/${item?.image?.replace(
                              //   "public/images/",
                              //   "images/"
                              // )}`}
                              width="15"
                              height="15"
                              className="media-icon"
                              style={{ backgroundColor: item?.bgColor }}
                              // style={{ backgroundColor: "red" }}
                            />
                          </a>
                        </li>
                      ))}
                </ul>
                {/* </li> */}
              </ul>
            </div>
            <div
              className="col-lg-3 col-md-3 col-sm-3 col-xs-6 screenReader"
              tabIndex="106"
            >
              <motion.h4 className="important-link">Important Links</motion.h4>
              <motion.ul
                className="impntLINK"
                whileInView={{ translateX: "0" }}
                // // animate={{opacity:1, translateX:0}}
                transition={{ duration: 0.5 }}
              >
                {footerData &&
                  footerData[0]?.importantLink
                    ?.filter((item) => item.isDeleted === false) // Filter only non-deleted items
                    ?.map((item, index) => (
                      <motion.li
                        className="screenReader"
                        tabIndex="107"
                        whileHover={{
                          translateX: "-20px",
                        }}
                        key={index}
                      >
                        <a href={item?.url} target="_blank" rel="noreferrer">
                          <img
                            src={`https://api.biharsports.org/${item?.image?.replace(
                              "public/images/",
                              "images/"
                            )}`}
                            // src={`http://localhost:5000/${item?.image?.replace(
                            //   "public/images/",
                            //   "images/"
                            // )}`}
                            alt={item?.title}
                          />
                        </a>
                      </motion.li>
                    ))}
              </motion.ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Footer;
