import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import Styles from '../../styles/SiteMap.module.css'
// import img from '../'

const SiteMap = () => {
  return (
    <>
      <Breadcrumb
        pageName="Sports-Equipment-Information"
        pageTitle="Sports-Equipment-Information"
        id="#Sports-Equipment-Information"
        img_url="./img/Sports-Equipment-Information-desktop.jpg"
        mobile_banner="./img/Sports-Equipment-Information-mobile.jpg"
      />

      <h1>SITEMAP</h1><br />

      <div className={Styles.container}>
        <div className={Styles.row}>
          <div className={`${Styles.boxmodel} `}>
            {/* <span className="top-title sitemap-title" style={{textAlign:"center"}}>
              Sitemap
            </span> */}
            <ul className={Styles.sitemap}>
              <li className="hmenu">
                <h4 className={Styles.mainmenu}><a href="/" >
                  Home </a></h4>
              </li>
              <li>
                <h4 className={Styles.mainmenu}>About Us</h4>
                <ul>

                  <li>
                    <a href="/aboutus" style={{ fontSize: "14px" }}>
                      Introduction
                    </a>
                  </li>
                  <li>
                    <a href="/visionandmission" style={{ fontSize: "14px" }}>
                      Mission, Vision and Objectives
                    </a>
                  </li>
                  <li>
                    <a href="/sportlegecy" style={{ fontSize: "14px" }}>
                      Our Sporting Heritage
                    </a>

                  </li>

                  <li>
                    <li><b><a href="/" style={{ fontSize: "16px" }}>
                      Message </a></b></li>

                    <ul>

                      <li>
                        <a href="/chiefminister" style={{ fontSize: "14px" }}>
                          Honorable Chief Minister, Bihar
                        </a>
                      </li>
                      <li>
                        <a href="/deputychiefminister" style={{ fontSize: "14px" }}>
                          Honorable Deputy Chief Minister, Bihar
                        </a>
                      </li>
                      <li>
                        <a href="/sports-minister" style={{ fontSize: "14px" }}>
                          Honorable Minister of Arts, Culture and Youth Department
                        </a>
                      </li>
                      <li>
                        <a href="/principal-secretary-sports-department" style={{ fontSize: "14px" }}>
                          Additional Chief Secretary, Art, Culture and Youth Department
                        </a>
                      </li>
                      <li>
                        <a href="/ravindran" style={{ fontSize: "14px" }}>
                          Director General cum Chief Executive Officer, Bihar State Sports Authority
                        </a>
                      </li>
                      <li>
                        <a href="/directorcumSecretaryBSSA" style={{ fontSize: "14px" }}>
                          Director cum Secretary, Bihar State Sports Authority
                        </a>
                      </li>


                    </ul>
                  </li>
                  <li>
                    <a href="/organizationalStru" style={{ fontSize: "14px" }}>
                      Organizational Structure
                    </a>
                  </li>
                  <li>
                    <a href="/prioritysports" style={{ fontSize: "14px" }}>
                      Priority Sports of Bihar
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <h4 className={Styles.mainmenu}><a>Policies and Schemes</a></h4>
                <ul>
                  <li>
                    <a
                      href="img/BSSA_Bye_Laws.pdf"
                      style={{ fontSize: "14px" }}
                    >
                      Sports Rules and Regulations
                    </a>
                  </li>
                  <li>
                    <a href="img/Sport_Rule_2023.pdf" style={{ fontSize: "14px" }}>
                      Sports Policy
                    </a>
                  </li>
                  <li>
                    <a href="/sportsreqrmtpolicy" style={{ fontSize: "14px" }}>
                      Sports Recruitment Policy
                    </a>
                  </li>
                  <li>
                    <a href="/kss" style={{ fontSize: "14px" }}>
                      Khel Samman Samaroh
                    </a>
                  </li>
                  <li>
                    <a href="/sportswelfarefund" style={{ fontSize: "14px" }}>
                      Players Welfare Fund
                    </a>
                  </li>
                  <li>
                    <a href="/grantssportsfederations" style={{ fontSize: "14px" }}>
                      Grants to Sports Associations
                    </a>
                  </li>
                  <li>

                    <li><b><a href="/" style={{ fontSize: "16px" }}>
                      Upcoming Policies  </a></b></li>
                    <ul>
                      <li>
                        <a
                          href="/sportsscholarshippolicy"
                          style={{ fontSize: "14px" }}
                        >
                          Sports Scholarship Policy
                        </a>
                      </li>
                      <li>
                        <a href="/inspiration" style={{ fontSize: "14px" }}>
                          Prerna
                        </a>
                      </li>

                      <li>
                        <a href="/able" style={{ fontSize: "14px" }}>
                          Saksham
                        </a>
                      </li>

                      <li>
                        <a href="/udaan" style={{ fontSize: "14px" }}>
                          Udaan
                        </a>
                      </li>
                    </ul>


                  </li>
                </ul>
              </li>
              <li>
                <h4 className={Styles.mainmenu}><a href="/" >
                  Our Achievements</a></h4>
                <ul>
                  <li>
                    <a
                      href="/internationalachievement"
                      style={{ fontSize: "14px" }}
                    >
                      International Achievements
                    </a>
                  </li>
                  <li>
                    <a href="/nationalachievement" style={{ fontSize: "14px" }}>
                      National Achievements
                    </a>
                  </li>

                  <li>
                    <a href="/statelevelachievement" style={{ fontSize: "14px" }}>
                      State level Achievements
                    </a>
                  </li>
                </ul>


              </li>

              <li>
                <h4 className={Styles.mainmenu}><a href="/" >
                  Sports Organizations</a></h4>
                <ul>
                  <li>
                    <a
                      href="img/eklavya_Kendra.pdf" target="_blank"
                      style={{ fontSize: "14px" }}
                    >
                      Eklavya Center
                    </a>
                  </li>
                  <li>
                    <a href="img/Kic.pdf" target="_blank" style={{ fontSize: "14px" }}>
                      Khelo India Center
                    </a>
                  </li>

                  <li>
                    <a href="img/khel_bhawan_summary_new .pdf"
                      target="_blank" style={{ fontSize: "14px" }}>
                      Sports Building
                    </a>
                  </li>

                  <li>
                    <a href="img/Training_Center.pdf"
                      target="_blank" style={{ fontSize: "14px" }}>
                      Training Center
                    </a>
                  </li>

                </ul>
              </li>

              <li>
                <h4 className={Styles.mainmenu}><a href="/tenders" >
                  Tenders</a></h4>
              </li>


              <li>
                <h4 className={Styles.mainmenu}><a href="/" >
                  Gallery</a></h4>
                <ul>
                  <li>
                    <a
                      href="/pressgallery"
                      style={{ fontSize: "14px" }}
                    >
                      Photos
                    </a>
                  </li>
                  <li>
                    <a href="/videos" style={{ fontSize: "14px" }}>
                      Videos
                    </a>
                  </li>
                </ul>
              </li>


              <li>
                <h4 className={Styles.mainmenu}><a href="/" >
                  Events and Programs</a></h4>
                <ul>
                  <li>
                    <a
                      href="/eventorg"
                      style={{ fontSize: "14px" }}
                    >
                      Past Events
                    </a>
                  </li>
                  <li>
                    <a href="/upcomingevents" style={{ fontSize: "14px" }}>
                      Upcoming Events
                    </a>
                  </li>
                </ul>
              </li>


              <li>
                <h4 className={Styles.mainmenu}><a href="/" >
                  Downloads</a></h4>
                <ul>
                  <li>
                    <a
                      href="#"
                      style={{ fontSize: "14px" }}
                    >
                      Notifications
                    </a>
                  </li>
                  {/* <li>
                        <a href="#"  style ={{fontSize: "14px"}}>
                        Press Release
                        </a>
                      </li> */}
                  <li>
                    <a
                      href="#"
                      style={{ fontSize: "14px" }}
                    >
                      Advertisement
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ fontSize: "14px" }}>
                      Annual Calendar
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ fontSize: "14px" }}>
                      Annual Report
                    </a>
                  </li>
                </ul>
              </li>


              <li>
                <h4 className={Styles.mainmenu}><a href="/" >
                  Contact Us</a></h4>
                <ul>
                  <li>
                    <a
                      href="/directory"
                      style={{ fontSize: "14px" }}
                    >
                      Directory
                    </a>
                  </li>
                  <li>
                    <a href="/query" style={{ fontSize: "14px" }}>
                      Message/Ask Question
                    </a>
                  </li>

                </ul>
              </li>

              <li>
                <h4 className={Styles.mainmenu}><a href="/dsodirectory" >
                  DSO Directory</a></h4>
              </li>



            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default SiteMap;
