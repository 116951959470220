import React, { useState, useEffect } from "react";
import api from "../../utils/ApiMethod";
import Breadcrumb from "../../components/common/Breadcrumb";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  Text,
} from "@chakra-ui/react";

const UpcomingEvent = () => {

  const [commingData, setCommingData] = useState([])

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("/get/upcomingevents");
      const data = await api.fetchData("/api/UpcomingEvent"); // url for db.json
      setCommingData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  console.log('upcoming', commingData)

  return (
    <>
      <Breadcrumb
        pageName="D.S.O-Directory"
        pageTitle="D.S.O-Directory"
        id="#dso-directory"
        // img_url="./img/DSO-directory-desktop-banner.jpg"
        // mobile_banner="./img/DSO-directory-mobile-banner.jpg"
        img_url={`https://api.biharsports.org/${commingData[0]?.bannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
        mobile_banner={`https://api.biharsports.org/${commingData[0]?.mobileBannerUrl.replace(
          "public/images/",
          "images/"
        )}`}
      />
      <Text
          textTransform="uppercase"
          p={"4px"}
          pb={"12px"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight="extrabold"
        >
          {/* {commingData[0]?.sportRequirementPolicyItem[0]?.sportName} */}
          Upcoming Events
        </Text>
      <Box>
        <TableContainer
          boxShadow={"lg"}
          alignContent={"center"}
          justifyContent={"center"}
          marginRight={"4%"}
          marginLeft={"4%"}
          paddingTop={"5px"}
          paddingBottom={"10px"}
        >
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th fontSize={"1.5rem"}>Sl.No.</Th>
                <Th fontSize={"1.5rem"}>Games</Th>
                <Th fontSize={"1.5rem"}>The Upcoming </Th>
              </Tr>
            </Thead>
            <Tbody>
              {commingData[0]?.upcomingEventItem
                ?.slice() // Create a shallow copy of the array to avoid mutating the original
                .reverse() // Reverse the array
                .filter((elem) => !elem.isDeleted) // Skip items where isDeleted is true
                .map((elem, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{elem?.sportName}</Td>
                    <Td>{elem?.sportDetails}</Td>
                  </Tr>
                ))}


            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default UpcomingEvent;
