import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import style from "./Athlite.module.css";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function Athlite() {
  return (
    <>
      <Breadcrumb
        pageName={""}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />

      <div>
        <h2 className={`${style.title}`}>
          <strong>67th National School Games 2023-24  </strong>Athletics Championship

        </h2>
      </div>

      <div className={`container ${style.athlite_details}`}>
        <div className={`${style.athlite_description}`}>
          <p>
          The 67th National School Games 2023 Athletics Championship Under-17 (Boys/Girls category) is being organized from 2nd February 2024 to 4th February 2024 at the Patliputra Sports Complex located in Kankarbagh, Patna, the capital of Bihar. 
          </p>
          {/* <p>
          It is being organized at the Patliputra Sports Complex located in Kankarbagh, Patna, the capital of Bihar, from 18th December 2023 to 20th December 2023.
          </p> */}
          <p style={{ color: `rgb(30, 30, 90)`, fontWeight: "600" }}>
          The last date for entry of players in the Athletics Championship has been fixed as 22nd January 2024. Along with this, the reporting date for the players participating in this championship has been fixed as 1st February 2024.
          </p>
          <br />
          <p>Name of in-charge of control room: Prem Kunj</p>

          <p>Mobile Number: 8210624562</p>
          <br />
          <p>Name of Nodal Officer: Shri Jai Narayan Kumar, District Sports Officer</p>
          <p>Mobile Number: 9661261352</p>
          <br />
        </div>
        <div className={`${style.athlite_img}`}>
          <img
            src="./img/running_sgfi.png"
            alt=""
            style={{ height: "auto", width: "30rem", padding: "2rem" }}
          />
          <p>Athletics </p>
          <a href="#" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
          View Details ↗
        </Button>
      </a>
        </div>
      </div>
      {/* <button className="btn">अधिक जानकारी के लिए क्लिक करें ↗</button> */}
      <a href="http://www.sgfi.org.in/" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
          Click for more information ↗
        </Button>
      </a>
    </>
  );
}

export default Athlite;
