import React, { useEffect, useState } from "react";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import api from "../../utils/ApiMethod";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import parisOlympic from "../../assets/images/paris_game_new.jpg";

import ninteenthAsian from "../../assets/images/ninteenth_asian_game_new.jpg";

import iccworldcup from "../../assets/images/icc_worldcup.jpeg";

const InternationalEvent = () => {
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("http://localhost:5000/api/internationalsportingevents");
      const data = await api.fetchData("/api/internationalsportingevents");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div>
      <div className="container">
        <div style={{ width: "98%", margin: "10px auto" }}>
          <h4
            className="section-heading"
            style={{ paddingTop: "15px", color: "black" }}
          >
            International Sporting Events
          </h4>
        </div>

        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={30}
          slidesPerView={1}
          navigation={true}
          loop={true}
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
          breakpoints={{
            320: {
              // width: 320,
              slidesPerView: 1,
              spaceBetween: 20,
            },

            425: {
              // width: 425,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 640px
            640: {
              // width: 640,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              // width: 768,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            // 1440: {
            //   slidesPerView: 4,
            //   spaceBetween: 30,
            // },
          }}
        >
          {data &&
            data.map((elem, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-slide zoom">
                  <div className="whatsNewCarousel__card">
                    <a href={elem.url} target="_blank">
                      <div
                        className="award__img"
                        style={{ width: "100% ", height: "100%" }}
                      >
                        <img
                          src={`https://api.biharsports.org/${elem.image.replace(
                            "public/images/",
                            "images/"
                          )}`}
                          alt="19th asian games 2023"
                        />
                      </div>
                      <div
                        className="whatsNewCarousel__text"
                        style={{ padding: "12%" }}
                      >
                        <h4>{elem.title}</h4>
                      </div>
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <a
                  href="https://ocasia.org/games/2-hangzhou-2023.html"
                  target="_blank"
                >
                  <div className="award__img">
                    <img src={ninteenthAsian} alt="19th asian games 2023" />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    <h4>19 वां एशियन गेम्स</h4>
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide> */}

          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <a href="https://www.icc-cricket.com/homepage" target="_blank">
                  <div className="award__img">
                    <img src={iccworldcup} alt="ICC men's worldcup 2023" />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    <h4>आईसीसी पुरुष वनडे विश्व कप</h4>
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide> */}

          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <a
                  href="https://olympics.com/en/olympic-games/paris-2024"
                  target="_blank"
                >
                  <div className="award__img">
                    <img src={parisOlympic} alt="Paris olympic" />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    <h4>पेरिस ओलंपिक्स 2024</h4>
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  );
};

export default InternationalEvent;
