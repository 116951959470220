import React from "react";

import Banner from "../components/Banner";
import Service from "../components/Service";
import NewEvent from "../components/NewEvent";
import Gallery from "../components/Gallery";

import { WhatNew } from "../components/WhatNew";
import ServiceCard from "../components/ServiceCard";
import ServiceNew from "../components/ServiceNew";
import GalleryTwo from "../components/GalleryTwo";
import BasicStructure from "../components/BasicStructure";
import BasicStructureTwo from "../components/BasicStructureTwo";

import FastMarquee from "../components/common/FastMarquee";
import CounterFeature from "../components/CounterFeature";
import AchieverCounter from "../components/AchieverCounter";
import InternationalNews from "../styles/InternationalNews";
import SportLegecy from "./sportLegecy/SportLegecy";
import InternationalEvent from "./internationalEventCalendar/InternationalEvent";
import GalleryThree from "../components/GalleryThree";
import NewHighlight from "../components/NewHighlights";
import GallaryAndVideo from "../components/GallaryAndVideo";
import TopHeadline from "../components/TopHeadline";
import NewTopHeadline from "../components/NewTopHeadline";

const Home = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        {/* AchieverCounter has no issue for responsive */}
        {/* <AchieverCounter /> */}
        {/* <CounterFeature /> */}
        {/* <InternationalNews/> */}

        {/* <SportLegecy/> */}
        {/* banner has no issue for responsive */}
        <Banner />

        {/* WhatNew has no issue for responsive */}
        <WhatNew />
        {/* <TopHeadline/> */}

        <NewTopHeadline />
        {/* ServiceNew has no issue for responsive */}
        <NewHighlight />
        <ServiceNew />

        {/* AchieverCounter has no issue for responsive */}

        {/* <AchieverCounter /> */}

        {/* <CounterFeature /> */}
        {/* <InternationalNews/> */}

        {/* <div
        style={{
          backgroundImage: `url("img/Character-animi_1.gif")`,
          // border: "2px solid red",
          backgroundSize: "cover", // Cover the entire element without repetition
          width: "200px", // Set the width of the div
          height: "200px",
          marginLeft: "20px",
        }}
      ></div> */}
        {/* <Service /> */}
        {/* <BasicStructure /> */}

        <div style={{ paddingBottom: "40px" }} className="paralex_structure">
          <BasicStructureTwo />
        </div>

        {/* <AchieverCounter /> */}
        {/*  */}
        <NewEvent />
        {/* ---------galleryThree just for checking */}

        {/* galleryThree ends */}

        {/* <Gallery /> */}
        <InternationalEvent />
        {/* <GalleryTwo /> */}

        {/* <GalleryThree /> */}
        <GallaryAndVideo />

        <FastMarquee />
        {/* <ServiceCard /> */}
      </div>
    </>
  );
};

export default Home;
