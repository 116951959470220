import React, { useEffect, useState } from "react";
import AnimatedMarquee from "./AnimatedMarquee";
import Twitter from "./Twitter";
import NewsScroller from "./NewsScroller";
import { motion, useAnimation } from "framer-motion";
import img from "../assets/images/parallaxforsocial.jpg";
import api from "../utils/ApiMethod";
import FacebookPageEmbed from "./FacebookWidget";

const NewEvent = () => {
  const [eventsList, setEventsList] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/get/event");
      setEventsList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <section
        className="newsEvents"
        style={{
          backgroundImage: `url(${img})`,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="row">
            <motion.div
              className="col-sm-12 col-md-4 col-lg-4"
              initial={{
                opacity: 0,
                scale: 0,
              }}
              whileInView={{ opacity: 1, scale: 1 }}
              // animate={{opacity:1, translateX:0}}
              transition={{ duration: 0.5 }}
            >
              {/* <span>
                {" "}
                <img
                  src="img/Character-animi_1.gif"
                  width="20%"
                  // margin="auto"
                />
              </span> */}
              <h4
                className="section-heading mobile-heading"
                style={{ color: "#fff" }}
              >
                {" "}
                Events
              </h4>
              <div className="latestEvents">
                <div className="latestEvents__Carousel">
                  <div
                    id="latestEvents-verticalCarousel"
                    className="carousel vertical slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {eventsList &&
                        eventsList.map((elem, index) => (
                          <div
                            className={`item ${index === 0 ? "active" : ""}`}
                            key={elem._id}
                          >
                            <img
                              src={`https://api.biharsports.org/${elem?.image?.replace(
                                "public/images/",
                                "images/"
                              )}`}
                              alt={elem?.title}
                              className="img-animate"
                            />
                            <div className="carousel-content">
                              <h3 className="carousel-title">{elem?.title}</h3>
                              <span className="display-block carousel-date">
                                {elem?.eventDate}
                              </span>
                              <p className="carousel-text">
                                {elem?.description}
                              </p>
                              <a
                                href="/"
                                className="btn btn-default button-link"
                              >
                                For more information{" "}
                                <i className="bi bi-arrow-up-right-circle"></i>
                              </a>
                            </div>
                          </div>
                        ))}

                      <a
                        className="carousel-control up"
                        href="#latestEvents-verticalCarousel"
                        data-slide="prev"
                      >
                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control down"
                        href="#latestEvents-verticalCarousel"
                        data-slide="next"
                      >
                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <NewsScroller />
            <div className="col-sm-12 col-md-4 col-lg-4">
              <h4
                className="section-heading mobile-heading"
                style={{ color: "#fff" }}
              >
                Social media
              </h4>
              <div className="socialWidget__area">
                <div id="social-tab">
                  <div className="tab-content clearfix">
                    {/* <Twitter /> */}
                    <FacebookPageEmbed />
                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default NewEvent;
