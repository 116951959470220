import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Gift.module.css";
import api from "../utils/ApiMethod";
import { useMediaQuery } from "@chakra-ui/react";

// Import Swiper styles
// import "swiper/css";
import "swiper/css/navigation";
// Import Swiper React components
// import { delay, motion, useAnimation } from 'framer-motion';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
// import './styles.css';

// import required modules
import { Navigation } from "swiper/modules";
import Styles from "../styles/BasicStructureTwo.module.css";

function NewHighlight() {
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/latestinformation");
      // const data = await api.fetchData("/highlight");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);


  const slidesPerView = isSmallScreen ? 1 : 3;
  return (
    <>
      <div className="container">
        <h4 className="section-heading">
          <h4 className="section-heading" style={{ color: "black" }}>
            Latest Information
          </h4>
        </h4>
        <Swiper
          autoplay={{
            delay: 2000,
          }}
          // autoplay={{
          //   delay: 2000, // Autoplay delay in milliseconds
          //   disableOnInteraction: true, // Autoplay stops even after user interaction
          // }}
          slidesPerView={slidesPerView}
          // rewind={true}
          navigation={true}
          modules={[Navigation]}
          // modules={[FreeMode, Pagination]}
          loop={true}
          spaceBetween={10}
          bgGradient={[
            "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
            //   'linear(to-t, blue.200, teal.500)',
            //   'linear(to-b, orange.100, purple.300)',
          ]}
          className={styles.mySwiper}
        // style={{"height" : "600px", border:"20px solid lightgray", margin:"0px"}}
        >
          {data.map((elem, index) => (
            <SwiperSlide >
              <div className="swiper-slide zoom" >
                <div className="whatsNewCarousel__card" >
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    <img
                      src={`https://api.biharsports.org/${elem?.image?.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    />
                    {/* <img   src={`http://localhost:4000/${elem?.image?.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}

                    {/* <img src={`${elem.ImageUrl}`}
                      alt={elem?.title}
                    /> */}


                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
export default NewHighlight;
