import { SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from "./style.module.css";
import Container from "react-bootstrap/Container";
import api from "../../utils/ApiMethod";
import downloadIcon from "../../assets/images/Download.jpeg";
import Styles from "../../styles/Tender.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
import { TenderData } from "./tenderData";
import img from "../../assets/images/Download.jpeg";
const Tenders = () => {
  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/tender");
      setData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div>
      {/* <Breadcrumb
        pageName="Tender"
        pageTitle="Tender"
        id="#Tender"


        img_url={`https://api.biharsports.org/${data[0]?.bannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
        mobile_banner={`https://api.biharsports.org/${data[0]?.mobileBannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
      /> */}
    <div className={styled.Container}>



      <h1 className="pt-2" style={{ marginBottom: "10px" }}>
        {/* निविदाएं */}
        {data[0]?.text}
      </h1>
      {/* input field for search the item */}
      <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for tender"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <table class="table table-striped ">
        <thead>
          <tr class="table-primary">
            <th className={`${Styles.th}`} scope="col">
              Sl.No.{" "}
            </th>
            <th className={`${Styles.head}`} scope="col">
              Subject
            </th>
            <th className={`${Styles.head}`} scope="col">
              {" "}
              Date
            </th>
            <th className={`${Styles.head}`} scope="col">
              Expire date
            </th>
            <th className={`${Styles.head}`} scope="col">
              Download
            </th>
          </tr>
        </thead>
          <tbody>
            {TenderData.filter((item) =>
              item.name.toLowerCase().includes(search.toLowerCase())
            ).map((item, i) => (
              <tr>
                <th scope="row">{i + 1}</th>
                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.name}
                </td>
                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.date}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.expiryDate}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  <a href={item.url} target="_blank">
                    <img className={Styles.table_image} src={img} />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
      </table>
    </div>
    </div>
  );
};

export default Tenders;
