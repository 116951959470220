import React, { useEffect, useState } from "react";
import { SimpleGrid, Center, Square, Circle, Text } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";
import { Box, Image } from "@chakra-ui/react";
import Styles from "./SportLegecy.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
import api from "../../utils/ApiMethod";

const SportLegecy = () => {
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("https://api.biharsports.org/api/latestinformation");

      // const data = await api.fetchData("/api/sportlegecy");
      const data = await api.fetchData(
        "https://api.biharsports.org/api/sportlegacy"
      );

      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const container = {
    display: "flex",
    width: "100%",
    padding: "4px",
    marginLeft: "22px",
    marginRight: "22px",
  };

  const box30 = {
    backgroundColor: "tomato",
    height: " auto",
    width: "40%",
  };

  const box70 = {
    textAlign: "left",
    backgroundColor: "red",
    padding: "10px",
    height: "auto",
    width: " 55%",
  };


  console.log('data[0]?.sportLegacy', data[0]?.sportLegacy)


  return (
    <div>
      {/* Our-Sports-Legacy-desktop.jpg */}

      <Breadcrumb
        pageName="Our Sports Legacy"
        pageTitle="Our Sports Legacy"
        id="#Our-Sports-Legacy"
        // img_url={`https://api.biharsports.org/${data[0]?.bannerUrl.replace(
        //   "public/images/",
        //   "images/"
        // )}`}

        img_url={`https://api.biharsports.org/${data[0]?.bannerUrl.replace(
          "public/images/",
          "images/"
        )}`}
        mobile_banner={`https://api.biharsports.org/${data[0]?.mobileBannerUrl.replace(
          "public/images/",
          "images/"
        )}`}
      // mobile_banner={`https://api.biharsports.org/${data[0]?.mobileBannerUrl.replace(
      //   "public/images/",
      //   "images/"
      // )}`}
      />
      {/* -------------------- our sport legecy ------------- */}
      <SimpleGrid
        columns={1}
        spacing={0}
        alignItems="flex-start"
        height="auto"
        w="100%"
        pl="2%"
        pr="2%"
        bg={"#252839"}
        mt="10"
        back
      >
        <Text bgClip="text" fontSize="6xl" fontWeight="extrabold" pt={"8px"}>
          <div className={Styles.textone}>
            <h2 data-text="हमारा खेल विरासत">{data[0]?.pageTitle}</h2>
          </div>
        </Text>

        <Box
          as={"paragraph"}
          height="100%"
          w="100%"
          textAlign={"left"}
          alignItems="flex-start"
          pt="2%"
          pb="2%"
          fontSize="1xl"
          fontWeight="extrabold"
          color={"white"}
        >
          <p>{data[0]?.pageDescription}</p>
        </Box>
      </SimpleGrid>
      {/* --------------------  ehdsour sport legecy ------------- */}

      {data[0]?.sportLegacy
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by createdAt in descending order
        .map((elem, index) => (
          <SimpleGrid
            columns={1}
            key={index}
            spacing={0}
            alignItems="flex-start"
            spacingX="40px"
          >
            <Box className={Styles.container}>
              <Box className={Styles.box30}>
                <Image
                  boxSize="400px"
                  w="100%"
                  //  src={`http://localhost:5000/${elem?.image.replace(
                  //   "public/images/",
                  //   "images/"
                  // )}`}
                  src={`https://api.biharsports.org/${elem?.image.replace(
                    "public/images/",
                    "images/"
                  )}`}
                  alt={elem.name}
                />
              </Box>

              <br></br>
              <Box className={Styles.box70}>
                <h1 style={{ paddingBottom: "3rem" }}>{elem.sportName}</h1>
                <p className={{ paddingTop: "30px" }}>{elem.description}</p>
              </Box>
            </Box>
            <br></br>
          </SimpleGrid>
        ))}
    </div>
  );
};
export default SportLegecy;
