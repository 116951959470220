import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const SportsDevelopmentPolicy = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Sports development policy"
        pageTitle="Sports development policy"
        id="#Sports development policy"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1>Sports Development Policy</h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  Sports Development Policy
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              The Government of Bihar has been formulating development related
              policies for players from time to time. The aim of these
              development policies made by the government is to increase the
              sports potential among the players and to provide a better
              platform to the players of the state. The responsibility of
              ensuring the implementation of sports related development policies
              formulated by the government has been given to Bihar State Sports
              Authority. Bihar State Sports Authority is making every effort to
              fulfil the objectives and goals of its establishment.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>
    </div>
  );
};
export default SportsDevelopmentPolicy;
