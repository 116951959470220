import React, { useEffect, useState } from "react";

import styled from "../tenders/style.module.css";

import api from "../../utils/ApiMethod";
import img from "../../assets/images/Download.jpeg";
import Styles from "../../styles/Tender.module.css";
const AnnualCalender = () => {
  const advertisement = [
    {
      id: 2,
      name: "Annual Sports Calendar",
      date: "2024 - 2025",
      expiryDate: "",
      url: "https://calendar.google.com/calendar/u/0/embed?src=104c8f6657a10af9c6323c32896270a1302d352b69099b47cbae1bc90e030fc7@group.calendar.google.com",
    },
    {
      id: 1,
      name: "Annual Sports Calendar",
      date: "2023 - 2024",
      expiryDate: "",
      url: "img/calender/bihar_sports_calender_2023-24.pdf",
    },
  ];

  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/PressRelease");
      setData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   handleFetchData();
  // }, []);

  return (
    <div className={styled.Container}>
      <h1 className="pt-2" style={{ marginBottom: "10px" }}>
        Annual Calendar
      </h1>

      <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for Prees Release"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <table class="table table-striped ">
        <thead>
          <tr class="table-primary">
            <th className={`${Styles.th}`} scope="col">
              S.No
            </th>
            <th className={`${Styles.head}`} scope="col">
              Subject
            </th>
            <th className={`${Styles.head}`} scope="col">
              {" "}
              Year
            </th>
            {/* <th className={`${Styles.head}`} scope="col">
              अंतिम तिथि
            </th> */}
            <th className={`${Styles.head}`} scope="col">
              Download
            </th>
          </tr>
        </thead>
        <tbody>
          {advertisement &&
            advertisement
              // .filter((item) =>
              //   item.name.toLowerCase().includes(search.toLowerCase())
              // )
              .map((item, i) => (
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td
                    className={Styles.table_cell}
                    style={{ textAlign: "left" }}
                  >
                    {item.name}
                  </td>
                  <td
                    className={Styles.table_cell}
                    style={{ textAlign: "left" }}
                  >
                    {item.date}
                  </td>

                  {/* <td
                    className={Styles.table_cell}
                    style={{ textAlign: "left" }}
                  >
                    {item.expiryDate}
                  </td> */}

                  <td
                    className={Styles.table_cell}
                    style={{ textAlign: "left" }}
                  >
                    <a href={item.url} target="_blank">
                      <img className={Styles.table_image} src={img} />
                    </a>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default AnnualCalender;
