import React, { useState, useEffect } from "react";
import api from "../../utils/ApiMethod";
import Breadcrumb from "../../components/common/Breadcrumb";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  Text,
} from "@chakra-ui/react";

const DSODirectory = () => {
  const [dso, setDso] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/dsodirectory");
      setDso(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <Breadcrumb
        pageName="D.S.O-Directory"
        pageTitle="D.S.O-Directory"
        id="#dso-directory"
        // img_url="./img/DSO-directory-desktop-banner.jpg"
        // mobile_banner="./img/DSO-directory-mobile-banner.jpg"
        img_url={`https://api.biharsports.org/${dso[0]?.bannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
        mobile_banner={`https://api.biharsports.org/${dso[0]?.mobileBannerUrl.replace(
          "public/images/",
          "images/"
        )}`}
      />
      {/* <h2>DSO Directory</h2> */}
      <Box
      backgroundColor="#b9bcc0"
      >
      <Text
          textTransform="uppercase"
          p={"4px"}
          pb={"12px"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight="extrabold"
        >
          {/* {commingData[0]?.sportRequirementPolicyItem[0]?.sportName} */}
          District Sports Officer Directory
        </Text>

        <TableContainer
          boxShadow={"lg"}
          alignContent={"center"}
          justifyContent={"center"}
          marginRight={"4%"}
          marginLeft={"4%"}
          paddingTop={"5px"}
          paddingBottom={"10px"}
        >
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th fontSize={"1.5rem"}>Sl.No.</Th>
                <Th fontSize={"1.5rem"}>Name of Officer</Th>
                <Th fontSize={"1.5rem"}>Mobile Number</Th>
                <Th fontSize={"1.5rem"}>Designation</Th>
                <Th fontSize={"1.5rem"}>Game</Th>
              </Tr>
            </Thead>

            {dso[0]?.dsoList.map((item, i) => (
              <Tbody>
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{item?.officerName}</Td>
                  <Td>{item?.mobileNumber}</Td>
                  <Td>{item?.designation}</Td>
                  <Td>{item?.sports}</Td>
                </Tr>
              </Tbody>
            ))}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default DSODirectory;
