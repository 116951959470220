import React from "react";

import stylesgfi from "./ESportLinks.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
import Button from "react-bootstrap/Button";

// import Athlite from "./Athlite";

export default function NationalSchoolGames() {
  return (
    <>
      <Breadcrumb
        pageName={NationalSchoolGames}
        pageTitle={"SGFI"}
        img_url={"img/ESportDesktopBanner.jpeg"}
        mobile_banner="./img/esportsMobileBanner.jpg"
      />
      <div></div>
      <div>
        <h1 className={`${stylesgfi.title}`}> ESPORTS Competition 2023 </h1>
      </div>

      <div className={`container ${stylesgfi.game_sgfi}`}>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdGIivuSPhhrKXPBi5CO1ZorsbHjHuXuJnsvciXc5odocrr1Q/viewform" target="_blank">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="img/EsportBGMI.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "0px" }}
            />
            <p>BGMI</p>
          </div>
        </a>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfz2P54MPcGibri4Hl735f2LFuDIJPe5YUSZ5plkEFQZt8ChQ/viewform" target="_blank">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="img/EsportChess.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "0px" }}
            />
            <p>Real Cricket 24</p>
          </div>
        </a>

        <a href="/https://docs.google.com/forms/d/e/1FAIpQLSdiPdnBOO5I82DXId0-qV7b5DsRGbZecy-HeGkNYrTSVrs1Sw/viewform" target="_blank">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="img/ESportCrirket.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "0px" }}
            />
            <p>Fifa 23</p>
          </div>
        </a>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLScZGU8C9RVIGniZMgnh9FwZLWBlFUh91XJ-EXfSDdXabkQk1A/viewform" target="_blank">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="/img/EsportFootball.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "0px" }}
            />
            <p>E-Chess</p>
          </div>
        </a>
      </div>
      {/* <a href="https://sgfionline.net/reports/MedalTally.aspx?NsgNbr=66" target="_blank">
        <Button variant="warning" style={{ marginBottom: "20px", color: "black" }}>
        मेडल तालिका ↗
        </Button>
      </a> */}

    </>
  );
}