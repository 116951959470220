import React, { useEffect, useState } from "react";
import "./Indivisiual.css";
import { SimpleGrid } from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import { delay, motion, useAnimation } from "framer-motion";

import Breadcrumb from "../../components/common/Breadcrumb";


import { Box, Text, Image } from "@chakra-ui/react";

const Ravindran = () => {
  const [ravindranPageData, setRavindranPageData] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/dirgenmessage");
      setRavindranPageData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);


  console.log('ravindran data--',ravindranPageData)

  return (
    <>
    <Breadcrumb
      pageName=" Bihar Chief minister message"
      pageTitle="Chief minister message"
      id="#chief-minister"
      img_url={`https://api.biharsports.org/${ravindranPageData[0]?.bannerUrl.replace(
        "public/images/",
        "images/"
      )}`}
      mobile_banner={`https://api.biharsports.org/${ravindranPageData[0]?.mobileBannerUrl.replace(
        "public/images/",
        "images/"
      )}`}
    />
    <SimpleGrid columns={[1, 1, 2, 2]} mt="20" mb="10">
      <motion.Box
        flexShrink={0}
        initial={{
          opacity: 0,
          translateX: -50,
          translateY: 0,
        }}
        whileInView={{
          opacity: 1,
          translateX: 0,
          translateY: 0,
        }}
        transition={{ duration: 0.3, delay: 1 }}
      >
        <Image
          borderRadius="lg"
          px={[5, 10, 20]}

          // src={ravindranPageData[0]?.image}
          src={`https://api.biharsports.org/${ravindranPageData[0]?.pageImage.replace(
            "public/images/",
            "images/"
          )}`}
          alt="Woman paying for a purchase"
          h="350px"
          w="100%"
        />
      </motion.Box>

      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize={["18", "42"]}
          letterSpacing="wide"
          color="teal.800"
          px={[4, 10, 20]}
        >
          {ravindranPageData[0]?.heading}
        </Text>

        <Text
          fontWeight="bold"
          textTransform="uppercase"
          align={"right"}
          fontSize={["10", "13"]}
          letterSpacing="wide"
          color="black"
          px={[4, 10, 20]}
        >
          {ravindranPageData[0]?.designation}
        </Text>

        <motion.Box
          initial={{
            opacity: 0,
            translateX: +50,
            translateY: 0,
          }}
          whileInView={{
            opacity: 1,
            translateX: 0,
            translateY: 0,
          }}
          transition={{ duration: 0.3, delay: 1 }}
        >
          <Text
            mt={2}
            color="gray.500"
            px={[5, 10, 20]}
            align={"left"}
            letterSpacing="wide"
            fontWeight="bold"
            py="2"
          >
            {expanded ? (
              <p>{ravindranPageData[0]?.paragraph}</p>
            ) : (
              <p>{ravindranPageData[0]?.paragraph?.slice(0, 850)}...</p>
            )}
            <button
              onClick={toggleExpand}
              style={{ color: "blue", fontWeight: "500" }}
            >
              {expanded ? "Read less" : "Read more"}
            </button>
          </Text>
        </motion.Box>
      </Box>
    </SimpleGrid>
  </>
  );
};
export default Ravindran;
