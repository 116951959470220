import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import style from "./Athlite.module.css";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function Cricket() {
  return (
    <>
      <Breadcrumb
        pageName={""}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />

      <div>
        <h2 className={`${style.title}`}>
          <strong>67th National School Games 2023 </strong> Cricket Championship
        </h2>
      </div>

      <div className={`container ${style.athlite_details}`}>
        <div className={`${style.athlite_description}`}>
          <p>
          Organization of Cricket Championship Under-17 (Boys/Girls category) of 67th National School Games 2023-24
          </p>
          <p>
          Being Organized from 16th January 2024 to 23rd January 2024 in three different stadiums of Bihar- <br />
            1.  Moinul Haq Stadium, Patna<br />
            2. Energy Stadium, Board Colony, Rajvanshi Nagar and <br /> 3. Jagjivan Stadium, Danapur, Patna.
            </p>

          <p style={{ color: `rgb(30, 30, 90)`, fontWeight: "600" }}>
          The last date for players to enter the cricket championship has been fixed as January 16. Along with this, the reporting date for the players participating in this championship has been fixed as 15th January 2024.
          </p>
          <br />
          <p>Control Room Address: Patliputra Sports Complex, Kankarbagh, Patna</p>
          <p>Name of in-charge of control room: Shiv Prakash</p>
          <p>Mobile Number: 8210624562</p>
          <br />
          <p>Name of Nodal Officer: Shri Jai Narayan Kumar, District Sports Officer</p>
          <p>Mobile Number: 9661261352</p>
          <br /><p/>
        </div>
        <div className={`${style.athlite_img}`}>
          <img
            src="./img/cricket_sgfi.png"
            alt=""
            style={{ height: "auto", width: "30rem", padding: "2rem" }}
          />
          <p>Cricket </p>

          <a href="img/cricket.pdf" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
          View Details ↗
        </Button>
      </a>
        </div>
      </div>
      <a href="http://www.sgfi.org.in/" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
        Click for more information ↗
        </Button>
      </a>
    </>
  );
}

export default Cricket;
