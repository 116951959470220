import { Container, Image, Text, SimpleGrid, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import api from "../../utils/ApiMethod";

const Vision_Mission = () => {

    const [missionData, setMissionData] = useState([]);

    const handleFetchData = async () => {
        try {
            const data = await api.fetchData("/get/missionandvission");
            setMissionData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        handleFetchData();
    }, []);


    return (
        <div>
            {missionData && missionData.map((elem, index) => (
                <>
                    <Breadcrumb
                        pageName="Vision and Mission"
                        pageTitle="Vision and Mission"
                        id="#mission"
                        img_url={`https://api.biharsports.org/${elem.bannerUrl.replace(
                            "public/images/",
                            "images/"
                        )}`}
                        mobile_banner={`https://api.biharsports.org/${elem.mobileBannerUrl.replace(
                            "public/images/",
                            "images/"
                        )}`}
                    />
                    <Container maxW='container' mt="10" p='0' w='95%' mb="10" >
                        <SimpleGrid columns={[1, 1, 2, 2]} columnGap={3} rowGap={2}>
                            {/* <VStack> */}
                            <Image src='img/5 Vision.jpg'></Image>
                            <GridItem colSpan={1} >
                                <Text
                                    fontWeight='bold'
                                    textTransform='uppercase'
                                    fontSize={['18', '42']}
                                    letterSpacing='wide'
                                    color="teal.800"
                                    px={[4, 10, 20]}
                                    py='2'
                                // opacity={0.4}
                                >
                                    Vision
                                </Text>
                                {/*------------------OUR MISSION CONTENT---------------------------  */}
                                <Text
                                    fontWeight='bold'
                                    py='2'
                                    color='gray.500'
                                >
                                    {elem?.VisionDescription}
                                </Text>
                            </GridItem >
                            {/* </VStack> */}

                            {/* <VStack> */}
                            <GridItem colSpan={1} >
                                <Text
                                    fontWeight='bold'
                                    textTransform='uppercase'
                                    fontSize={['18', '42']}
                                    letterSpacing='wide'
                                    color="teal.800"
                                    px={[4, 10, 20]}
                                >
                                    Goal
                                </Text>
                                {/*------------------OUR VISION CONTENT---------------------------  */}
                                <Text py='10'
                                    fontWeight='bold'
                                    color='gray.500'
                                >
                                    {elem?.objectiveDescription}
                                </Text>

                            </GridItem >
                            <Image src='./img/vission-photo-new.jpeg' alt="vission"></Image>
                            {/* </VStack> */}
                            <Image src='img/motive.jpg'></Image>

                            <GridItem colSpan={1} >

                                <Text
                                    fontWeight='bold'
                                    textTransform='uppercase'
                                    fontSize={['18', '42']}
                                    letterSpacing='wide'
                                    color="teal.800"
                                    px={[4, 10, 20]}
                                    py='2'

                                >
                                    Mission
                                </Text>
                                <Text
                                    fontWeight='bold'
                                    py='2'
                                    color='gray.500'
                                >

                                    {/* <ul>
                                        <li>राज्य भर से प्रतिभावान खिलाड़ियों को तलाशना और तराशना।</li>
                                        <li>राष्ट्रीय और अंतर्राष्ट्रीय प्रतियोगिताओं के लिए खिलाड़ियों में उचित कौशल का विकास करना।</li>
                                        <li>खिलाड़ियों के निरंतर अभ्यास के लिए समय-समय पर विभिन्न स्तरों की प्रतियोगिताओं का आयोजन करना।</li>
                                    </ul> */}
                                    {elem?.missionDescription} 
                                </Text>
                            </GridItem >

                        </SimpleGrid>
                        {/*----------------------------------- right side */}
                        {/* </Flex> */}
                    </Container>
                </>


            ))}

        </div>

    )
}

export default Vision_Mission