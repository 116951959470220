import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import style from "./Athlite.module.css";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const WeightLifting = () => {
  return (
    <>
      <Breadcrumb
        pageName={""}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />

      <div>
        <h2 className={`${style.title}`}>
          <strong>67th National School Games (2023-24) </strong>Weightlifting
          Championship
        </h2>
      </div>

      <div className={`container ${style.athlite_details}`}>
        <div className={`${style.athlite_description}`}>
          <p>
            67th National School Games, Weightlifting Championship Age Group:
            Under-17 (Boys & Girls)
         
           
            
          </p>
          <br />
          <p style={{ color: `rgb(30, 30, 90)`, fontWeight: "600" }}>
          Championship Date: 12th February – 15th February 2024 <br />
            Venue: Patliputra Sports Complex, Kankarbagh, Patna <br />
            Last date for entry of players: 1st February 2024 <br />
            Reporting date for players: 11th February 2024
          </p>
          <br />
          <p> Control Room Address: Patliputra Sports Complex, Kankarbagh, Patna</p>
          <p>Name of in-charge of control room: Keshav Kumar</p>

          <p>Mobile Number: 9162915276</p>
          <br />
          <p>Name of Nodal Officer: Shri Jai Narayan Kumar, District Sports Officer</p>
          <p>Mobile Number: 9661261352</p>
          <br />
        </div>
        <div className={`${style.athlite_img}`}>
          <img
            src="./img/weight_liftingsgfi.png"
            alt=""
            style={{ height: "auto", width: "30rem", padding: "2rem" }}
          />
          <p>Weightlifting </p>
        </div>
      </div>
      <a href="http://www.sgfi.org.in/" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
        Click for more information ↗
        </Button>
      </a>
    </>
  );
};

export default WeightLifting;
