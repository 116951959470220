import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const HostelGroundBooking = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Hostel and stadium service"
        pageTitle="Hostel and stadium service"
        id="#Sports-recruitment-Policy"
        img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1>Facility to book hostels, stadiums and sports grounds</h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  Hostel Booking Facility
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              Patliputra Sports Complex is not only providing a better field to
              the players to enhance their talent in sports, but special
              arrangements have also been made here for the players to stay. A
              total of 3 hostels are there in the sports complex. Each hostel
              building is different. Separate hostels have been constructed for
              male and female players so that the players do not face any kind
              of problem. Along with this, there is also a well-organized
              arrangement for the stay of sports trainers here. For booking
              hostel, you will have to submit an application form in the office.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  Playground Booking Facility
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              Bihar State Sports Authority is not only playing an important role
              in the development of sports and players but is also providing
              full support in the successful conduction of sports events in the
              state. Both indoor and outdoor stadiums can be easily booked at
              the Patliputra Sports Complex located in Kankarbagh, Patna, the
              capital of Bihar. To book the stadium, you have to submit an
              application in the office of Bihar State Sports Authority. For
              more information regarding reserving stadiums, download the PDF
              below.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>
    </div>
  );
};
export default HostelGroundBooking;
